import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { login } from "services/auth";
import { message } from "antd";
import styles from "./login.module.css";
import { BASE_URL } from "../../baseURL";
import WeyzoLogo from "../../assets/img/WeyzoLogo.svg";
import Loading from '../../components/Loading/Loading';
import { useUser } from "context/UserContext";

function Login() {
  let navigate = useNavigate();
  const { setUser } = useUser();
  const [resetPassword, setResetPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    doLogin(values);
  };

  const goResetPassword = async () => {
    if (!resetPassword) setResetPassword(true);
    else setResetPassword(false);
  };

  const goRegister = () => {
    navigate("/register");
  };

  const doLogin = async (values) => {
    setIsLoading(true);

    login(values.username, values.password)
      .then((res) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("userId", res.id);
        message.success("Logged in successfully");
        setUser(res)
        navigate("/");
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        message.error("Login error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendPasswordEmail = async () => {
    return await fetch(
      `${BASE_URL}/api/v1/entrance/send-password-recovery-email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          emailAddress: email,
        }),
      }
    )
    .then((_res) => {
      message.success(
        "Reset password instructions have been sent to your email account."
      );
      navigate("/checkEmail", { state: { email: email } });
      setResetPassword(false);
    })
    .catch((error) => {
      message.error("Error sending password instructions");
      console.error(error);
    });
  };

  return (
    <>
      <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={WeyzoLogo}
          alt="WeyzoLogo"
        />
        <div style={{ fontWeight: "bold" }} className="medium-text">
          { resetPassword ? "Enter your email" : "Welcome" }
        </div>
      </div>
      <div className={styles.loginContainer}>
        {isLoading ? (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
          ) : (
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                className={styles.Input}
                darkback="true"
                label="Work Email:"
                placeholder="Type your work email"
                type="text"
                value={email}
                onChange={(value) => setEmail(value.target.value)}
              />
            </Form.Item>

            {!resetPassword && (
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  className={styles.Input}
                  darkback="true"
                  label="Password:"
                  placeholder="Password"
                  type="text"
                  style={{ marginBottom: 15 }}
                />
              </Form.Item>
            )}

            {!resetPassword && (
              <div style={{ marginBottom: 30 }}>
                <span>
                  <Button
                    type="link"
                    onClick={goResetPassword}
                    style={{ color: "#6a42e9" }}
                    className={`font-regular ${styles["link-button"]}`}
                  >
                    Forgot your password?
                  </Button>
                </span>
              </div>
            )}

            {resetPassword && (
              <div style={{ marginBottom: 30 }}>
                <span
                  className="font-medium"
                  style={{ marginRight: 5, color: "var(--gray-700)" }}
                >
                  Already have an account?
                </span>
                <span>
                  <Button
                    type="text"
                    onClick={goResetPassword}
                    style={{ color: "#6a42e9" }}
                    className="font-regular"
                  >
                    Go back to login
                  </Button>
                </span>
              </div>
            )}

            <Form.Item>
              {!resetPassword && (
                <Button
                  size="large"
                  style={{ height: 46 }}
                  className="primary-btn font-regular full-btn"
                  htmlType="submit"
                >
                  Sign In
                </Button>
              )}
              {resetPassword && (
                <Button
                  size="large"
                  style={{ height: 46 }}
                  className="primary-btn font-regular full-btn"
                  onClick={sendPasswordEmail}
                >
                  Send password recovery instructions
                </Button>
              )}
            </Form.Item>

            <div style={{ textAlign: "center" }}>
              <span className="font-medium">
                Don't have an account?
                <Button
                  type="text"
                  style={{ color: "#6a42e9" }}
                  className="font-regular"
                  onClick={goRegister}
                >
                  Sign up
                </Button>
              </span>
            </div>
          </Form> 
        )}
      </div>
    </>
  );
}

export default Login;
