import axios from "axios";
import { BASE_URL } from "baseURL";
import { generateFixedQuestions } from "../views/FormBuilder/utils/generateQuestions"

const createFormUrl = `${BASE_URL}/create_form`;
const updateFormUrl = `${BASE_URL}/update_form`;

export const createFormData = async ({
  formData,
  logo,
  fixedQuestions,
  qualifyingQuestions,
  customFixedQuestions,
  dataCustomFixedQuestions,
  status
}) => {
  let newData = {};
  
  const form = {
    title: formData.title,
    subtitle: formData.subtitle,
    design: {
      primary_color: formData["design-primary-color"] ? formData["design-primary-color"].hex : "#8847E6",
      secondary_color: formData["design-secondary-color"] ? formData["design-secondary-color"].hex : "#FCF9F0",
      button_text: formData["button-text"] || "Request a Demo",
      button_background: formData["button-background-color"] ? formData["button-background-color"].hex : "#8847E6",
      button_text_color: formData["button-text-color"] ? formData["button-text-color"].hex : "#ffffff"
    },
    status: status,
    watermark_disabled: formData.watermark_disabled,
  };

  const meetingData = {
    meeting_title: formData["meeting-title"],
    meeting_duration: formData["meeting-duration"],
    meeting_description: formData["meeting-description"],
  };

  const newQualifyingQuestions = qualifyingQuestions &&
    qualifyingQuestions.filter(question => Object.keys(question).length > 0 && question.label)
    .map((question, index) => {
      return {
      name: question.label.toLowerCase().replace(/\s+/g, '-'),
      label: question.label,
      value: "true",
      value_type: "text",
      question_type: "qualifying",
      active: true,
      required: true,
      order: question.order,
      placeholder: question.label,
      question_options: question.question_options.map((option, optionIndex) => {
        return {
          ...option,
          order: optionIndex
        }
      })
    }
  });

  const defaultFixedQuestions = generateFixedQuestions();
  const newFixedQuestions = fixedQuestions && defaultFixedQuestions.map((question, index) => {
    if (fixedQuestions[question.key]) {
      return {
        ...question,
        order: fixedQuestions[question.key].order,
        active: fixedQuestions[question.key].active,
        required: fixedQuestions[question.key].required,
      };
    }
    return question;
  });

  const newCustomFixedQuestions = dataCustomFixedQuestions &&
    customFixedQuestions
    .filter(question => Object.keys(question).length > 0 && question.label)
    .map((question, index) => {
      if (dataCustomFixedQuestions[question.key]) {
        console.log('question', question)
        return {
          ...question,
          name: dataCustomFixedQuestions[question.key].label.toLowerCase().replace(/\s+/g, '-'),
          order: question.order,
          active: question.active,
          placeholder: dataCustomFixedQuestions[question.key].label,
          label: dataCustomFixedQuestions[question.key].label,
          value_type: question.value_type,
          required: dataCustomFixedQuestions[question.key].required,
        };
      }
      return question;
    }
  );

  console.log('newCustomFixedQuestions', newCustomFixedQuestions)

  newData.fixed_questions = newFixedQuestions;
  newData.qualifying_questions = newQualifyingQuestions;
  newData.min_score = formData.scoring;
  newData.form = form;
  newData.legal = formData["legal-text"];
  newData.meeting = meetingData;
  newData.custom_fixed_questions = newCustomFixedQuestions;
  newData.logo = logo
  newData.video_call = formData.video_call

  const token = localStorage.getItem("token");
  let response;
  try {
    response = await axios.post(createFormUrl, newData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
    response = err.response;
  }
  return response.data;
};

export const updateFormData = async ({
  formData
}) => {
  const token = localStorage.getItem("token");
  let response;
  try {
    response = await axios.post(updateFormUrl, formData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
    response = err.response;
  }
  return response.data;
}

export const getForm = async ({ id }) => {
  const token = localStorage.getItem("token");
  let response
  try {
    response = await axios.get(`${BASE_URL}/forms/show/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
  } catch(err) {
    console.error(err)
    response = err.response
  }
  return response.data
}
