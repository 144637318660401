import React from "react";

import "./upgradePlan.module.css";
import styles from "./upgradePlan.module.css";

import { UpgradePlanButton } from "components/Buttons/Buttons";
import { CheckIcon, NoCheckIcon } from "components/CheckIcon/CheckIcon";

const FreePlan = () => {
  const renderHeader = () => {
    return (
      <>
        <div className={styles["plan-header-container"]}>
          <p className={styles["plan-header-plan"]}>Free</p>
          <p className={styles["plan-header-price"]}>Always Free</p>
          <p className={styles["plan-header-time"]}>forever</p>
        </div>
      </>
    );
  };
  const renderWorkspace = () => {
    return (
      <>
        <div className={styles["feat-container"]}>
          <h3>Workspace</h3>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Up to 200 leads per form</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>1 seat</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>1 dynamic form</p>
          </div>
        </div>
      </>
    );
  };
  const renderFeatures = () => {
    return (
      <>
        <div className={styles["feat-container"]}>
          <h3>Features</h3>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Google Calendar connection</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Advanced scheduling</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Real-time qualification</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Route & assign leads</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Lead management</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Embed code</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Full-page form with Weyzo's subdomain</p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>Analytics</p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>Remove Weyzo's watermark</p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>Notification & email reminder</p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>Round robin assignment</p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>
              Full-page form with custom domain
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>
              Integrations with Salesforce, Hubspot and more
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>
              Route & assign leads within your CRM
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check"]}>
              Custom routing and assignment rules
            </p>
          </div>
          <div
            style={{ marginBottom: 0 }}
            className={styles["list-item-container"]}
          >
            <NoCheckIcon />
            <p className={styles["no-check"]}>Dedicated Account Management</p>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className={styles["plan-container"]}>
        {renderHeader()}
        <UpgradePlanButton planLabel="FREE" />
        {renderWorkspace()}
        {renderFeatures()}
      </div>
    </>
  );
};
export default FreePlan;
