import React from "react";
import TextRich from "components/TextRich/TextRich";
import { useFormContext, Controller } from "react-hook-form";

import { Input, Select } from "antd";

import "./components.module.css";
import styles from "./components.module.css";

const MeetingPanel = (props) => {
  const { control } = useFormContext();
  const { title, description, duration = 30 } = props;

  const optionsSelect = [
    {
      value: 15,
      label: "15 min",
    },
    {
      value: 30,
      label: "30 min",
    },
    {
      value: 45,
      label: "45 min",
    },
    {
      value: 60,
      label: "60 min",
    },
  ];

  return (
    <>
      <div className="mt-16">
        <p className={styles["input-label-text"]}>
          Meeting Duration:
        </p>
      </div>
      <Controller
        name="meeting-duration"
        control={control}
        render={({ field }) => <Select options={optionsSelect} {...field} />}
        defaultValue={duration}
      />
      <div className="mt-32">
        <p className={styles["input-label-text"]}>
          Meeting Title:
        </p>
      </div>
      <Controller
        name="meeting-title"
        control={control}
        defaultValue={title}
        render={({ field }) => (
          <Input size="large" value={title} placeholder="Add Meeting Title" {...field} className={styles.Input} />
        )}
      />
      <div className="mt-32">
        <p className={styles["input-label-text"]}>
          Meeting Description:
        </p>
      </div>
      <Controller
        name="meeting-description"
        control={control}
        defaultValue={description}
        render={({ field }) => (
          <TextRich {...field} value={description} placeholder="Add Meeting Description" />
        )}
      />
    </>
  );
};

export default MeetingPanel;
