import React from "react";

import "./upgradePlan.module.css";
import styles from "./upgradePlan.module.css";

import { UpgradePlanButton } from "components/Buttons/Buttons";
import { CheckIcon } from "components/CheckIcon/CheckIcon";

const EnterprisePlan = () => {
  const renderHeader = () => {
    return (
      <>
        <div className={styles["plan-header-container"]}>
          <p className={styles["plan-header-plan"]}>Enterprise</p>
          <p className={styles["plan-header-price"]}>300€ + 99€ / month </p>
          <p className={styles["plan-header-time"]}>+10 users</p>
        </div>
      </>
    );
  };
  const renderWorkspace = () => {
    return (
      <>
        <div className={styles["feat-container"]}>
          <h3>Workspace</h3>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Unlimited leads per form</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Unlimited Seats</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Unlimited dynamic forms</p>
          </div>
        </div>
      </>
    );
  };
  const renderFeatures = () => {
    return (
      <>
        <div className={styles["feat-container"]}>
          <h3>Features</h3>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Google Calendar connection</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Advanced scheduling</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Real-time qualification</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Route & assign leads</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Lead management</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Embed code</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Full-page form with Weyzo's subdomain</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Analytics</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Remove Weyzo's watermark</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Notification & email reminder</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Round robin assignment</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Full-page form with custom domain</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Integrations with Salesforce, Hubspot and more</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Route & assign leads within your CRM</p>
          </div>
          <div className={styles["list-item-container"]}>
            <CheckIcon />
            <p>Custom routing and assignment rules</p>
          </div>
          <div
            style={{ marginBottom: 0 }}
            className={styles["list-item-container"]}
          >
            <CheckIcon />
            <p>Dedicated Account Management</p>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className={styles["plan-container"]}>
        {renderHeader()}
        <UpgradePlanButton planLabel="ENTERPRISE" />
        {renderWorkspace()}
        {renderFeatures()}
      </div>
    </>
  );
};
export default EnterprisePlan;
