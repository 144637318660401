import React, { useState } from "react";
import { Input, InputProps } from "antd";
// STYLES
import styles from "./w-input.module.css";

function WInput({
  label,
  placeholder,
  style,
  type,
  value,
  disabled,
  onChangeValue,
}) {
  return (
    <div style={style}>
      <div
        style={{
          marginBottom: 5,
          color: "var(--gray-700)",
          marginRight: "10px",
        }}
        className="font-medium"
      >
        {label}
      </div>
      <Input
        placeholder={placeholder}
        bordered={false}
        size="large"
        value={value}
        className={`${styles.Winput} font-medium`}
        type={type ? type : "text"}
        disabled={disabled}
        onChange={(e) => onChangeValue(e.target.value)}
      />
    </div>
  );
}

export default WInput;
