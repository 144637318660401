import { BASE_URL } from "baseURL"

import axios from 'axios'

const token = localStorage.getItem('token')

const apiInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    }
})

export const checkPlan = async id => {
    let response
    try {
        response = await apiInstance.get(`/check_plan/${id}`)
    } catch(err) {
        console.error(err)
        response = err.response
    }
    return response.data
}

export const upgradePlan = async ({ plan, user, token }) => {
    const body = {
        plan,
        id: user.organization.id,
        token
    }
    let response
    try {
        response = await apiInstance.post('/update_plan', body)
    } catch(err) {
        console.error(err)
        response = err.response
    }
    return response.data
}

export const getStripeUrl = async () => {
    let response
    try {
        response = await apiInstance.get('/payment/create_checkout_session')
    } catch(err) {
        console.error(err)
        response = err.response
    }
    return response.data
}
