import React from "react";
import { useFormContext } from "react-hook-form";

import "./switchInput.module.css";
import styles from "./switchInput.module.css";

const SwitchInputQualy = ({ name, qualify, handleQualifyChange }) => {
  const { register } = useFormContext();

  return (
    <>
      <label className={styles.switch}>
        <input
          id={name}
          name={name}
          className={styles.input}
          type="checkbox"
          {...register(name, { value: qualify })}
          onChange={handleQualifyChange}
          defaultChecked={qualify}
        />
        <span className={styles.slider}></span>
      </label>
    </>
  );
};
export default SwitchInputQualy;
