import React from "react";
import formIcon from "../../../assets/img/dashboard-form/icon.svg";
import styles from "../forms.module.css";

const EmptyLayout = ({openNewFormBuilder}) => {
  return (
    <>
      <div className={styles["no-form-container"]}>
        <img
          src={formIcon}
          alt="form icon"
          className={styles["no-form-image"]}
        />
        <p className={styles["no-form-title"]}>There is no forms yet!</p>
        <p className={styles["no-form-text"]}>
          Start creating your first Weyzo form.
        </p>
        <button className={styles["no-form-button"]} onClick={openNewFormBuilder}>
          Create a form
        </button>
      </div>
    </>
  );
};

export default EmptyLayout;
