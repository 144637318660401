import React, { useState, useEffect, useRef } from "react";
import { message, Popconfirm, Avatar, Button, Modal } from "antd";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { BASE_URL } from "../../baseURL";

import { ReactComponent as Calendar } from "assets/img/profile/calendar_connect.svg";
import { ReactComponent as Check } from "assets/img/profile/check.svg";

import { updateNotification } from "services/notification-report";

import NotificationSettings from "./components/NotificationSettings";
import ReportSettings from "./components/ReportSettings";
import WInput from "components/w-input/w-input";
import WSelect from "components/w-select/w-select";
import Loading from "components/Loading/Loading";
import timezonesData from 'utils/timezones.json';

function Profile() {
  let token = localStorage.getItem("token");
  const { user, setUser } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const inputHidden = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [userState, setUserState] = useState({});

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    setPageLoading(true);
    async function fetchData() {
      await delay(1000);
      setUserState({
        ...user,
        editUser: true
      });
      setPageLoading(false);
    }
    fetchData();

    if (localStorage.getItem('calendars')) {
      setCalendars(JSON.parse(localStorage.getItem('calendars')))
      setIsModalOpen(true)
    }
    console.log('Granted User access to Profile')
  }, []);

  const saveCalendar = async (calendar_id) => {
    const data = {
      id: user.id,
      calendarId: calendar_id,
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    try {
      const response = await axios.post(`${BASE_URL}/saveCalendar`, data, {
        headers,
      });
      setIsModalOpen(false);
      message.success("Calendar saved succesfully updated successfully");
      localStorage.removeItem('calendars');
      setUserState(() => ({
        ...userState,
        calendar_id: response.data.calendar_id,
        id: response.data.id
      }));
      return response.data;
    } catch (err) {
      console.error('Save Calendar Error', err);
      throw err;
    }
  };

  const syncCalendar = async (code) => {
    if (localStorage.getItem('calendars')) {
      setIsModalOpen(true);
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/setAccessCode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          code: code,
        }),
      });
  
      const calendarsData = await response.json();
    
      if (calendarsData.length === 1) {
        saveCalendar(calendarsData[0].id);
      } else {
        setCalendars(calendarsData);
        localStorage.setItem('calendars', JSON.stringify(calendarsData));
        setIsModalOpen(true)
      }
    } catch (error) {
      message.error("Error synchronizing google calendar");
      console.error(error);
    }
  };

  useEffect(() => {
    const accessCode = new URL(window.location.href).searchParams.get("code");

    if (user && user.oauth_token === null && accessCode && !localStorage.getItem('calendars')) {
      syncCalendar(accessCode);
    }
  }, []);

  const resetPassword = async () => {
    return await fetch(
      `${BASE_URL}/api/v1/entrance/send-password-recovery-email`,
      {
        method: "POST",
        body: JSON.stringify({ emailAddress: user.emailAddress }),
      }
    )
      .then((response) => {
        message.success(
          "Reset password instructions have been sent to the user's email address."
        );
      })
      .catch((error) => {
        message.error("Error sending password instructions");
        console.error(error);
      });
  };

  const confirm = (e) => {
    resetPassword();
  };

  const cancel = (e) => {
    console.log("Canceled");
  };

  const updateUserImage = async (imageData) => {
    let params = {
      id: user.id,
      image: imageData,
    };

    if (!imageData) {
      params.image = "";
    }

    await axios.post(`${BASE_URL}/update_user`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "applicationjson",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setUserState(() => ({
        ...userState,
        editUser: true,
        image: response.data.image,
        id: response.data.id
      }));
      setUser({...user, image: response.data.image});
      message.success("User updated successfully");
      return response.data;
    })
    .catch((error) => {
      message.error("Error updating user");
      console.error(error);
      throw error;
    });
  };

  const updatePassword = async () => {
    let params = {
      id: user.id,
      password: newPassword,
    };

    if (newPassword !== confirmNewPassword) {
      message.error("Passwords do not match.");
      return Promise.reject("Passwords do not match.");
    }

    await axios.post(`${BASE_URL}/update_user`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "applicationjson",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setUserState(() => ({
        ...userState,
        editUser: true,
        image: response.data.image,
        id: response.data.id
      }));
      setUser({...user, image: response.data.image});
      message.success("User updated successfully");
      return response.data;
    })
    .catch((error) => {
      message.error("Error updating user");
      console.error(error);
      throw error;
    });
  };

  const updateUser = async () => {
    let params = {
      id: userState.id,
      fullName: userState.fullName,
      emailAddress: userState.emailAddress
    }
  
    await axios.post(`${BASE_URL}/update_user`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "applicationjson",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setUserState(() => ({
        ...userState,
        editUser: true,
        fullName: response.data.fullName,
        emailAddress: response.data.emailAddress,
        image: response.data.image,
        id: response.data.id
      }));
      setUser(()  => ({
        ...user,
        fullName: response.data.fullName,
        emailAddress: response.data.emailAddress,
        image: response.data.image
      }));
      message.success("User updated successfully");
      return response.data;
    })
    .catch((error) => {
      message.error("Error updating user");
      console.error(error);
      throw error;
    });
  };

  const startSync = () => {
    return axios
      .get(`${BASE_URL}/getData`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .then((json) => {
        window.open(json);
        return json;
      })
      .catch((error) => {
        message.error("Sync calendar error");
        console.error(error);
        throw error;
      });
  };

  const getImageUser = () => {
    inputHidden.current.click();
  };

  const updloadImage = (event) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const onChangeSwitch = async (event, name) => {
    await updateNotification({ name, value: event.target.checked, title: event.target.title })
      .then((response) => {
        setUserState(() => ({
          ...userState,
          notificationSettings: response.data.notificationSettings,
          reportSettings: response.data.reportSettings
        }));
        setUser(() => ({
          ...user,
          notificationSettings: response.data.notificationSettings,
          reportSettings: response.data.reportSettings
        }));
        message.success("User notifications updated successfully");
      })
      .catch((error) => {
        message.error("Error updating user notifications");
        console.error(error);
      }
    );
  }

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  return (
    userState.id !== null && (
      <>
        { calendars.length > 1 && (
          <Modal title="Choose your calendar" visible={isModalOpen} footer={null}>
            {calendars.map((cal, i) => {
              return (
                <div
                  key={i}
                  style={{
                    border: "1px solid #ccc",
                    padding: "20px",
                    margin: "10px",
                  }}
                >
                  <p>
                    <b>{cal.summary}</b>
                  </p>
                  {cal.description && <p>{cal.description}</p>}{" "}
                  <Button
                    className="primary-btn font-regular"
                    onClick={() => saveCalendar(cal.id)}
                    block
                  >
                    Use this calendar
                  </Button>
                </div>
              );
            })}
          </Modal>
        )}
        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div
            className="title-purple"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
            }}
          >
            Personal Information
            {userState.editUser ? (
              <Button
                size="large"
                className="primary-btn font-regular"
                onClick={() => setUserState({ ...userState, editUser: false })}
              >
                Edit
              </Button>
            ) : (
              <Button
                size="large"
                className="primary-btn font-regular"
                onClick={() => updateUser()}
              >
                Save
              </Button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <Avatar
                  style={{ marginRight: 20 }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={userState.image}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="title-purple">{userState.fullName}</span>
                  <span className="default-text">{userState.emailAddress}</span>
                </div>
              </div>
              <div>
                <Button
                  size="large"
                  className="primary-btn-outline font-regular"
                  style={{ marginRight: 30 }}
                  onClick={() => getImageUser()}
                >
                  Upload Image
                </Button>
                <input
                  style={{ display: "none" }}
                  ref={inputHidden}
                  type="file"
                  onChange={(e) =>
                    updloadImage(e).then((res) => updateUserImage(res))
                  }
                />
                <Button
                  size="large"
                  onClick={() => updateUserImage(false)}
                  className="primary-btn font-regular"
                >
                  Delete Image
                </Button>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <WInput
                label="Name"
                value={userState.fullName}
                placeholder="John"
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setUserState({ ...userState, fullName: value })}
                disabled={userState.editUser}
              />
              <WInput
                label="Work Email"
                value={userState.emailAddress}
                placeholder="john.doe@weyzo.io"
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setUserState({ ...userState, emailAddress: value })}
                disabled
              />
              <WSelect
                label="Time Zone"
                options={timezonesData}
                value={userState.userTimeZone}
                placeholder="(GMT) Western Europe Time, London, Lisbon, Casablanca"
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setUserState({ ...userState, userTimeZone: value })}
                disabled={userState.editUser}
              />
            </div>
          </div>
        </div>
        {/* CHANGE USER PASSWORD */}
        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div
            className="title-purple"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
            }}
          >
            Change Password
            <Button
              size="large"
              className="primary-btn font-regular"
              onClick={() => updatePassword()}
            >
              Save
            </Button>
          </div>
          <div>
            <WInput
              label="New Password"
              placeholder="******"
              type="password"
              value={newPassword}
              style={{ marginBottom: 20 }}
              onChangeValue={(value) => setNewPassword(value)}
            />
            <WInput
              label="Confirm New Password"
              placeholder="******"
              type="password"
              value={confirmNewPassword}
              style={{ marginBottom: 20 }}
              onChangeValue={(value) => setConfirmNewPassword(value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 50 }}>
            <p style={{ marginRight: 30, marginBottom: 0 }}>
              Forgot your password?
            </p>

            <Popconfirm
              title="Reset password via email?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a style={{ color: "var(--primary)" }}>
                Reset password via email
              </a>
            </Popconfirm>
          </div>
        </div>

        <NotificationSettings onChangeSwitch={onChangeSwitch} userState={userState} />

        <ReportSettings onChangeSwitch={onChangeSwitch} userState={userState} />

        {/* CONNECT TO YOUR CALENDAR */}
        <div className="container-lg">
          <div
            className="title-purple"
            style={{
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
            }}
          >
            Calendar Connection
          </div>
          <div
            className="font-medium"
            style={{ color: "var(--gray-placeholder-dark)", marginBottom: 30 }}
          >
            Connect your calendar to let weyzo know when you're available and
            update your calendar as events are scheduled.
          </div>
          <div
            className="container-card"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Calendar style={{ marginRight: 30 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <p
                  className="font-medium"
                  style={{ color: "var(--dark-900)", margin: 0, fontSize: 16 }}
                >
                  {userState.emailAddress}
                </p>
                <p
                  className="font-regular"
                  style={{ color: "var(--dark-700)", margin: 0, fontSize: 14 }}
                >
                  Google Calendar
                </p>
              </div>
            </div>

            <div
              className="font-medium"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--gray-700)",
              }}
            >
              {userState.calendar_id ? (
                <>
                  <Check style={{ marginRight: 10 }} /> Connected
                </>
              ) : (
                <>
                  Disconnected
                </>
              )}
            </div>

            <div>
              {userState.calendar_id ? (
                <Button
                  className="danger-btn-rounded font-bold"
                  style={ { marginLeft: 16 } }
                  onClick={() => {
                    Modal.confirm({
                      title: 'Are you sure you want to disconnect Google Calendar?',
                      onOk() {
                        setUserState({ ...userState, calendar_id: "" });
                        updateUser();
                      },
                    });
                  }}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  className="primary-btn-rounded font-bold"
                  onClick={() => {
                    if (localStorage.getItem('calendars')) {
                      setIsModalOpen(true);
                    } else {
                      startSync();
                    }
                  }}
                >
                  { localStorage.getItem('calendars') ? 'Choose calendar' : 'Connect' }
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Profile;
