import WInput from "components/w-input/w-input";
import WSelect from "components/w-select/w-select";
import LeadStatus from "components/lead-status/lead-status";
import { Button } from "antd";
// IMAGES
import { ReactComponent as Edit } from "assets/img/edit.svg";
import { ReactComponent as Trash } from "assets/img/trash.svg";
import { useState, useEffect } from "react";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { message, Popconfirm } from "antd";
import React from "react";

import { BrowserRouter as Router, useParams } from "react-router-dom";
import { BASE_URL } from "../../baseURL";

function Lead() {
  let token = localStorage.getItem("token");
  let params = useParams();
  let id = params.id;
  const [lead, setLead] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [meeting, setMeeting] = useState("");
  const [editLead, setEditLead] = useState(false);
  const [users, setUsers] = useState(null);
  const [newUsers, setNewUsers] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getLead();
    getUsers();
  }, []);

  const getUsers = async () => {
    return await fetch(`${BASE_URL}/users`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setUsers(json);
        setNewUsers([]);
        json.map((user) => {
          setNewUsers((newUsers) => [
            ...newUsers,
            { value: user.id, name: user.fullName },
          ]);
        });
        return json;
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error);
      });
  };

  const getLead = async () => {
    return await fetch(`${BASE_URL}/leads/show/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLead(data);
        setEmail(data.email);
        setPhone(data.phone);
        setPosition(data.position);
        setCompany(data.company_name);
        setCountry(data.country);
        setAssignedTo(data.user.id);
        setMeeting(data.meeting_date);
        return data;
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error);
      });
  };

  const confirm = (e) => {
    console.log(e);
    deleteLead(lead.id);
  };

  const cancel = (e) => {
    console.log(e);
  };

  async function deleteLead(lead_id) {
    return await fetch(`${BASE_URL}/delete_leads/${lead_id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        message.success("Lead has been deleted");
        navigate("/leads");
        return json;
      })
      .catch((error) => {
        console.error(error);
        message.error("Error deleting lead");
      });
  }

  const updateLead = async () => {
    return await fetch(`${BASE_URL}/update_lead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id: lead.id,
        email: email,
        phone: phone,
        position: position,
        company_name: company,
        country: country,
        user: assignedTo,
        meeting: meeting,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        setEmail(data.email);
        setPhone(data.phone);
        setPosition(data.position);
        setCompany(data.company_name);
        setCountry(data.country);
        setAssignedTo(data.user.id);
        setMeeting(data.meeting_date);
        message.success("Lead updated successfully");
        setEditLead(false);
        return await fetch(`${BASE_URL}/users/show/${assignedTo}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((json) => {
            setAssignedTo(json.id);
            return json;
          })
          .catch((error) => {
            message.error("Lead not found");
            console.error(error);
          });
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error);
      });
  };

  return (
    lead != null &&
    users !== null && (
      <>
        <div className="container-lg">
          <div style={{ marginBottom: 30 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottomColor: "#ECECEC",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                paddingBottom: 5,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="title-purple" style={{ marginRight: 20 }}>
                  {lead.name}
                </div>
                <div style={{ marginRight: 20 }}>
                  <LeadStatus qualification={lead.status} />
                </div>
                <div className="font-regular">
                  <span className="font-bold">{lead.lead_scoring}</span>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {editLead ? (
                  <Button
                    style={{ marginRight: 20 }}
                    size="large"
                    className="primary-btn font-regular"
                    onClick={() => updateLead()}
                  >
                    Save
                  </Button>
                ) : (
                  <Edit
                    style={{ marginRight: 20 }}
                    onClick={() => setEditLead(true)}
                  />
                )}
                <Popconfirm
                  title="Are you sure to delete this lead?"
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Trash />
                </Popconfirm>
              </div>
            </div>
            <div
              className="font-medium"
              style={{ color: "var(--gray-placeholder-dark)", marginTop: 8 }}
            >
              Created at: {Moment(lead.createdAt).format("DD-MM-YYYY - H:m")} ·
              Updated at: {Moment(lead.updatedAt).format("DD-MM-YYYY - H:m")}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", columnGap: 30 }}>
            <div style={{ flexGrow: 1 }}>
              <WInput
                label="Work Email"
                placeholder="john@doee.com"
                value={email}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setEmail(value)}
                disabled={!editLead}
              />
              <WInput
                label="Position"
                placeholder="Product Manager"
                value={position}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setPosition(value)}
                disabled={!editLead}
              />
              <WSelect
                style={{ marginBottom: 20 }}
                value={assignedTo}
                placeHolder={"Assigned To"}
                label="Assigned To:"
                options={newUsers}
                onChange={(value) => setAssignedTo(value)}
                disabled={!editLead}
              />
            </div>
            <div style={{ flexGrow: 1 }}>
              <WInput
                label="Phone"
                placeholder="+33654458874"
                value={phone}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setPhone(value)}
                disabled={!editLead}
              />
              <WInput
                label="Company"
                placeholder=""
                value={company}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setCompany(value)}
                disabled={!editLead}
              />
              <WInput
                label="Country"
                placeholder=""
                value={country}
                style={{ marginBottom: 20 }}
                onChangeValue={(value) => setCountry(value)}
                disabled={!editLead}
              />
              <WInput
                label="Meeting"
                placeholder="01.01.22 - 20.30"
                value={Moment(meeting).format("DD-MM-YYYY - H:m")}
                onChangeValue={(value) => setMeeting(value)}
                disabled={!editLead}
              />
            </div>
          </div>
        </div>

        <div className="container-lg" style={{ marginTop: 40 }}>
          <div style={{ borderBottom: "1px solid #ECECEC" }}>
            <div style={{ marginBottom: 5 }}>
              <span className="title-purple"> Form Responses </span> <span> { lead.form.title } </span> 
            </div>
          </div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            {lead.lead_answers.map((answer, i) => {
              return (
                <div className="container-card" style={{ width: "30%" }}>
                  <div
                    className="font-medium"
                    style={{ color: "var(--gray-900)" }}
                  >
                    {answer.question_name}
                  </div>
                  <div
                    className="font-medium"
                    style={{ color: "var(--gray-700)" }}
                  >
                    {answer.answer_value}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    )
  );
}

export default Lead;
