import { BASE_URL } from "../baseURL";

const axios = require("axios");

let token = localStorage.getItem("token");

let loginInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// OBTAIN CSRF TOKEN FOR CALLS
export const checkLogin = async () => {
  const token = localStorage.getItem("token");
  
  if (!token) {
    return Promise.reject("No token provided");
  }

  try {
    const weyzoInstance = axios.create({
      baseURL: `${BASE_URL}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const userId = localStorage.getItem("userId");
    
    const result = await weyzoInstance.get(`/users/show/${userId}`);
    
    if (result.status >= 200 && result.status < 300) {
      console.log('User exists and is logged in');
      return result.data;
    } else {
      return Promise.reject("Failed to fetch user data");
    }
  } catch (error) {
    console.error('Error while checking login:', error);
    return Promise.reject(error);
  }
};

// OBTAIN CSRF TOKEN FOR CALLS
export const login = (email, password) => {
  const loginData = {
    emailAddress: email,
    password,
    json: true,
  };

  return new Promise((resolve, reject) => {
    loginInstance
      .put("/api/v1/entrance/login", loginData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.error("ERR LOGIN", err);
        reject(err);
      });
  });
};

export const register = (email, password, name) => {
  const registerData = {
    emailAddress: email,
    password: password,
    fullName: name,
    json: true,
  };

  return new Promise((resolve, reject) => {
    loginInstance
      .post("/api/v1/entrance/signup", registerData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.error("ERR REGISTER", err);
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    loginInstance
      .post("/api/v1/account/logout")
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.error("ERR LOGOUT", err);
        reject(err);
      });
  });
}
