import React from 'react';
import { Layout } from "antd";

const FullWidthLayout = ({ children, whiteBackground }) => (
  <>
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      <Layout>
        <Layout.Content
          id="main"
          style={{
            backgroundColor: whiteBackground ? 'white' : "var(--tertiary-light-2)",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <main> {children} </main>
        </Layout.Content>
      </Layout>
    </Layout>
  </>
);

export default FullWidthLayout;