import React from "react";
import { InputNumber } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import { useFormContext, Controller } from "react-hook-form";

import "./components.module.css";
import styles from "./components.module.css";

const ScoringPanel = (props) => {
  const { qualifyingQuestions, minScore } = props;
  const { control } = useFormContext();

  const countQualifyingQuestions = (questions) => {
    return questions.reduce((count, question) => {
      if (question.active) {
        const hasQualifyingOption = question.question_options.some(
          option => option.qualify && option.active
        );
        return hasQualifyingOption ? count + 1 : count;
      }
      return count;
    }, 0);
  };

  const totalQualifyingQuestions = countQualifyingQuestions(qualifyingQuestions);

  return (
    <>
      <div className={styles["minimum-score"]}>
        <p>Minimum Qualifying Responses:</p>
        <Controller
          name="scoring"
          control={control}
          defaultValue={minScore}
          render={({ field }) => (
            <InputNumber
              controls={{
                upIcon: <CaretUpOutlined />,
                downIcon: <CaretDownOutlined />,
              }}
              size="large"
              max={totalQualifyingQuestions}
              min={0}
              {...field}
              value={field.value > totalQualifyingQuestions ? totalQualifyingQuestions : field.value }
            />
          )}
        />
      </div>
    </>
  );
};

export default ScoringPanel;
