import React from "react";
import { useLocation } from "react-router-dom";
import Card from "../../assets/img/Rectangle 254.svg";
import Union from "../../assets/img/Union.svg";
import { BASE_URL } from "../../baseURL";
import { message } from "antd";

function CheckEmail() {
  let location = useLocation();

  const resendEmail = async () => {
    return await fetch(
      `${BASE_URL}/api/v1/entrance/send-password-recovery-email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          emailAddress: location.state.email,
        }),
      }
    )
      .then((response) => {
        message.success(
          "Reset password instructions have been sent to your email account."
        );
      })
      .catch((error) => {
        message.error("Error sending password instructions");
        console.error(error);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 40,
        }}
      >
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={Union}
          alt="Union"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: 80,
            height: 80,
            borderRadius: 100,
            backgroundColor: "#F3EDDE",
          }}
        >
          <img style={{ width: "auto", height: "auto" }} src={Card} alt="Card"/>
        </div>

        <h1 className={"font-bold"} style={{ fontSize: 30, marginTop: 30 }}>
          Check Your Email
        </h1>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            className={"font-regular"}
            style={{ textAlign: "center", width: 200 }}
          >
            Please check the email address {location.state.email} for
            instructions to reset your password.
          </p>
        </div>

        <button
          className={"font-bold"}
          style={{
            width: 300,
            height: 46,
            paddingTop: 7,
            paddingBottom: 7,
            backgroundColor: "transparent",
            borderRadius: 10,
            border: "1px solid black",
            cursor: "pointer",
          }}
          onClick={resendEmail}
        >
          Resend email
        </button>
      </div>
    </>
  );
}

export default CheckEmail;
