import React from "react";
import { useNavigate } from "react-router-dom";

import "./upgradePlan.module.css";
import styles from "./upgradePlan.module.css";

import logo from "../../assets/img/WeyzoLogo.svg";
import arrow from "../../assets/img/arrow-left.svg";

import FreePlan from "./FreePlan";
import ProPlan from "./ProPlan";
import EnterprisePlan from "./EnterprisePlan";
import { useUser } from "context/UserContext";

const UpgradePlan = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const clickBack = () => {
    navigate(-1);
  };

  const renderBack = () => {
    return (
      <>
        <div className={styles.back}>
          <img
            onClick={clickBack}
            className={styles.arrow}
            src={arrow}
            alt="arrow left"
          />
          <img src={logo} alt="weyzo logo" />
        </div>
      </>
    );
  };

  const renderPageHeader = () => {
    return (
      <>
        <div className={styles["page-header-container"]}>
          <h1>Upgrade your plan</h1>
          <h3>Upgrade your plan to get much more out of Weyzo.</h3>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.upgrade}>
        {renderBack()}
        {renderPageHeader()}
        <div className={styles["plans-container"]}>
          <FreePlan />
          <ProPlan user={user} />
          <EnterprisePlan />
        </div>
      </div>
    </>
  );
};
export default UpgradePlan;
