import React from 'react';
import { Layout } from "antd";
import SideNavbar from "components/SideNavbar/SideNavbar"
import Header from "components/header/header"

const AppLayout = ({ children }) => (
  <>
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      <SideNavbar />
      <Layout>
        <Header />
        <Layout.Content
          id="main"
          style={{
            backgroundColor: "var(--tertiary-light-2)",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          className="main-container"
        >
          <main> {children} </main>
        </Layout.Content>
      </Layout>
    </Layout>
  </>
);

export default AppLayout;