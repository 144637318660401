import React from "react";
import { useWatch } from "react-hook-form";
import { Input } from "antd";

import "../../formBuilder.module.css";
import styles from "../../formBuilder.module.css";
import "./preview.module.css";
import previewStyles from "./preview.module.css";

const CustomQuestionsComp = ({ control, question }) => {
  const renderQuestion = useWatch({
    control,
    name: `customFixedQuestions[${question.key}].label`,
    defaultValue: question.label
  });
  const requiredQuestion = useWatch({
    control,
    name: `customFixedQuestions[${question.key}].required`,
    defaultValue: question.required
  });
  const typeQuestion = useWatch({
    control,
    name: `customFixedQuestions[${question.key}].value_type`,
    defaultValue: question.value_type
  });

  return (
    <>
      <div key={question.key} className={styles["preview-q-title"]}>
        {renderQuestion && (
          <Input
            readOnly
            className={previewStyles["preview-input"]}
            type={typeQuestion && typeQuestion}
            placeholder={`${
              renderQuestion ? renderQuestion : question.placeholder
            }${requiredQuestion ? "*" : ""}`}
          />
        )}
      </div>
    </>
  );
};

export default CustomQuestionsComp;
