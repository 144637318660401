import React, { useEffect, useState } from 'react';
import { Badge, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from "../../context/UserContext";

// STYLES
import styles from './SideNavbar.module.css';
// ICONS
import { ReactComponent as WeyzoLogo} from 'assets/img/weyzo_logo.svg';
import { ReactComponent as DashboardSelected} from 'assets/img/side-menu/icon_dashboard_selected.svg';
import { ReactComponent as Dashboard} from 'assets/img/side-menu/icon_dashboard.svg';
import { ReactComponent as Leads} from 'assets/img/side-menu/icon_leads.svg';
import { ReactComponent as LeadsSelected} from 'assets/img/side-menu/icon_leads_selected.svg';
import { ReactComponent as Meetings} from 'assets/img/side-menu/icon_meetings.svg';
import { ReactComponent as MeetingsSelected} from 'assets/img/side-menu/icon_meetings_selected.svg';
import { ReactComponent as Forms} from 'assets/img/side-menu/icon_forms.svg';
import { ReactComponent as FormsSelected} from 'assets/img/side-menu/icon_forms_selected.svg';

import { ReactComponent as Availability } from 'assets/img/side-menu/clock-navbar.svg'
import { ReactComponent as AvailabilitySelected } from 'assets/img/side-menu/clock-navbar_selected.svg'

// STORE
import useGlobalStore from 'store/globalStore';

import { UpgradeModalButton } from 'components/Buttons/Buttons';

const { Sider } = Layout;

function SideNavbar() {
  const { user, plan } = useUser();
  const setActiveRoute = useGlobalStore((state) => state.setActiveRoute);
  const [itemSelected, setItemSelected] = useState(1);
  const { pathname } = useLocation()

  const [menuItems, setMenuItems] = useState([
    {
      id: 1,
      name: 'Analytics',
      route: '/',
      icon: <Dashboard />,
      iconSelected: <DashboardSelected />,
      badge: 0
    },
    {
      id: 2,
      name: 'Forms',
      route: '/forms',
      icon: <Forms />,
      iconSelected: <FormsSelected />,
      badge: 0
    },
    {
      id: 3,
      name: 'Inbound Leads',
      route: '/leads',
      icon: <Leads />,
      iconSelected: <LeadsSelected />,
      badge: 0
    },
    {
      id:4,
      name: 'Meetings',
      route: '/meetings',
      icon: <Meetings />,
      iconSelected: <MeetingsSelected />,
      badge: 0
    },
    {
      id: 5,
      name: 'Availability',
      route: '/availability',
      icon: <Availability />,
      iconSelected: <AvailabilitySelected />,
      badge: 0
    }
  ]);

  useEffect(() => {
    const item = menuItems?.filter(i => i?.route === pathname)
    if(item.length > 0){
      selectedRoute(item[0])
    }
  }, []);

  const selectedRoute = (item) => {
    setItemSelected(item.id);
    setActiveRoute(item);
  }

  return (
    <Sider
      className={styles.sider}
      width={250}
      theme='light'
      breakpoint='lg'
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 23, marginBottom: 40, paddingLeft: 25}}>
        <Link to="/"><WeyzoLogo /></Link>
      </div>
      <Menu
        className={styles.menu}
        mode="inline"
        onClick={(item) => selectedRoute(menuItems[item.key-1])}
        defaultSelectedKeys={[`${itemSelected}`]}
        items={menuItems.map(
          (item, index) => ({
            key: item.id,
            icon: (item.name !== 'Forms' || (item.name === 'Forms' && user !== null && user.user_type !== 'sales')) && (itemSelected == item.id ? item.iconSelected : item.icon),
            label: (item.name !== 'Forms' || (item.name === 'Forms' && user !== null && user.user_type !== 'sales')) && <Link to={item.route}><div className={`font-bold ${styles.menuItem}`} onClick={() => {
              selectedRoute(item)
            }}>{item.name} <Badge count={item.badge} className={styles.menuBadge} /></div></Link>
          })
        )}
        activeKey={`${itemSelected}`}
        selectedKeys={`${itemSelected}`}
      />
      { plan === 'FREE' && ( 
        <div className={styles['plan-container']}>
          <div className={styles['button-container']}>
            <p>
              <b>You're currently running in a {plan} plan.</b> Upgrade your plan to get much more out of Weyzo
            </p>
            <UpgradeModalButton>
              View plans
            </UpgradeModalButton>
          </div>
        </div>
      )}
    </Sider>
  )
}

export default SideNavbar;<Link to="/">Home</Link>
