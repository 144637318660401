import React from 'react';
import { useWatch } from 'react-hook-form';
import { Button } from 'antd';
import styles from "./preview.module.css";

const QualifyingQuestionOptionsPreview = ({ control, option, questionKey }) => {
  const renderQuestionOption = useWatch({
    control,
    name: `qualifyingQuestions[${questionKey}].question_options[${option.key}].value`,
    defaultValue: option.value
  });

  return (
    <div key={option.key}>
      {renderQuestionOption && (
        <Button className={styles['answer-button']} key={option.key}>
          { renderQuestionOption }
        </Button>
      )}
    </div>
  );
};

export default QualifyingQuestionOptionsPreview;
