import React from 'react'
import { useIcons } from 'hooks/formHooks'

import './components.module.css'
import styles from './components.module.css'

const PanelIcon = ({ icon, text }) => {

    return (
        <>
            <div className={styles['panel-icon']}>
                <img src={useIcons(icon)} alt="icon" />
                <p>{text}</p>
            </div>
        </>
    )
}
export default PanelIcon