import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Divider, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useUser } from "../../context/UserContext";
import Moment from "moment";
import { BASE_URL } from "../../baseURL";
import { translate_user_type } from "services/helper";

import WInput from "components/w-input/w-input";
import { ReactComponent as ChevronLeftPurple } from "assets/img/profile/chevron-left-purple.svg";
import ModalUpgradePlan from "components/Modals/ModalUpgradePlan/ModalUpgradePlan";
import Loading from "components/Loading/Loading";

import styles from "./company_profile.module.css";

function CompanyProfile() {
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  const { user, plan } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [organizationState, setOrganizationState] = useState(null);
  const [editOrganization, setEditOrganization] = useState(true);
  const [upgradeModalopen, setUpgradeModalOpen] = useState(false);
  const inputHidden = useRef(null);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    setPageLoading(true);
    async function fetchData() {
      await delay(1000);
      getOrganization(user.organization.id);
      getUsers();
      setPageLoading(false);
    }
    fetchData();
    console.log("User authenticated at Organization profile");
  }, []);

  const getUsers = async () => {
    return await fetch(`${BASE_URL}/users`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setUsers(json);
        return json;
      })
      .catch((error) => {
        message.error("Error getting users");
        console.error(error);
      });
  };

  const getOrganization = (idOrganization) => {
    return axios
      .get(`${BASE_URL}/organizations/show/${idOrganization}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .then((json) => {
        setOrganizationState(json);
        return json;
      })
      .catch((error) => {
        message.error("Error getting company data");
        console.error(error);
        throw error;
      });
  };

  const updateOrganizationImage = async (imageData) => {
    let params = {
      id: organizationState.id,
      image: imageData,
    }

    if (!imageData) {
      params.image = "";
    }

    try {
      const response = await axios.post(`${BASE_URL}/update_organization`, params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      const json = response.data;
      setOrganizationState(json);
      setEditOrganization(true);
      message.success("Company updated successfully");
      return json;
    } catch (error) {
      message.error("Error updating company");
      console.error(error);
      throw error;
    }
  };
  
  const updateOrganization = async () => {  
    let params = {
      id: organizationState.id,
      address: organizationState.address,
      city: organizationState.city,
      country: organizationState.country,
      industry: organizationState.industry,
      linkedin: organizationState.linkedin,
      name: organizationState.name,
      phone: organizationState.phone,
      postal_code: organizationState.postal_code,
      province: organizationState.province,
      website: organizationState.website
    };
  
    try {
      const response = await axios.post(`${BASE_URL}/update_organization`, params, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      const json = response.data;
      setOrganizationState(json);
      setEditOrganization(true);
      message.success("Company updated successfully");
      return json;
    } catch (error) {
      message.error("Error updating company");
      console.error(error);
      throw error;
    }
  };
  
  const getImageOrganization = () => {
    inputHidden.current.click();
  };

  const updloadImage = async (event) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const onClickNewUser = () => {
    if (plan === "FREE") {
      setUpgradeModalOpen(true);
      return;
    }
    navigate("/user_company");
  };

  const onCloseModal = () => {
    setUpgradeModalOpen(false);
  };

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  return (
    user !== null &&
    organizationState !== null && (
      <>
        <ModalUpgradePlan open={upgradeModalopen} onClose={onCloseModal}>
          You have a free Weyzo account which allows you to create only 1 user.
          Want to create more?
        </ModalUpgradePlan>
        {/* PERSONAL INFORMATION */}
        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div
            className="title-purple"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
            }}
          >
            Company profile
            {user.user_type !== "sales" &&
              (editOrganization ? (
                <Button
                  size="large"
                  className="primary-btn font-regular"
                  onClick={() => setEditOrganization(false)}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  size="large"
                  className="primary-btn font-regular"
                  onClick={() => updateOrganization()}
                >
                  Save
                </Button>
              ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <Avatar
                  style={{ marginRight: 20 }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={organizationState.image}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="title-purple">{organizationState.name}</span>
                  {/*<span className='default-text'>{user.emailAddress}</span>*/}
                </div>
              </div>
              {user.user_type !== "sales" && (
                <div>
                  <Button
                    size="large"
                    className="primary-btn-outline font-regular"
                    style={{ marginRight: 30 }}
                    onClick={() => getImageOrganization()}
                  >
                    Upload Image
                  </Button>
                  <input
                    style={{ display: "none" }}
                    ref={inputHidden}
                    type="file"
                    onChange={(e) =>
                      updloadImage(e).then((res) => updateOrganizationImage(res))
                    }
                  />
                  <Button
                    size="large"
                    onClick={() => updateOrganizationImage(false)}
                    className="primary-btn font-regular"
                  >
                    Delete Image
                  </Button>
                </div>
              )}
            </div>
            <div className={styles["inputs-container"]} style={{ flex: 1 }}>
              <WInput
                label="Company name"
                value={organizationState.name}
                placeholder="John"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, name: value })
                }
                disabled={editOrganization}
              />
              <WInput
                label="Website"
                value={organizationState.website}
                placeholder="https://www.weyzo.io"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, website: value })
                }
                disabled={editOrganization}
              />
              <WInput
                label="Phone"
                value={organizationState.phone}
                placeholder="+34 666777888"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, phone: value })
                }                
                disabled={editOrganization}
              />
              <WInput
                label="Industry"
                value={organizationState.industry}
                placeholder="Software & technology"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, industry: value })
                }                
                disabled={editOrganization}
              />
              <WInput
                label="Linkedin"
                value={organizationState.linkedin}
                placeholder="https://www.linkedin.com/company/weyzo/"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, linkedin: value })
                }                
                disabled={editOrganization}
              />
              <WInput
                label="Address"
                value={organizationState.address}
                placeholder="Street 1, 2ºA"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, website: value })
                }                
                disabled={editOrganization}
              />
              <WInput
                label="Postal code"
                value={organizationState.postal_code}
                placeholder="08000"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, postal_code: value })
                }                
                disabled={editOrganization}
              />
              <WInput
                label="City"
                value={organizationState.city}
                placeholder="Barcelona"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, city: value })
                }                
                disabled={editOrganization}
              />
              <WInput
                label="Province"
                value={organizationState.province}
                placeholder="Barcelona"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, province: value })
                }                
                disabled={editOrganization}
              />
              <WInput
                label="Country"
                value={organizationState.country}
                placeholder="Spain"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setOrganizationState({ ...organizationState, country: value })
                }                
                disabled={editOrganization}
              />
            </div>
          </div>
        </div>

        {/* ADMIN USERS MANAGEMENT */}
        {user.user_type !== "sales" && users !== null && (
          <div className="container-lg" style={{ marginBottom: 30 }}>
            <div
              style={{
                borderBottom: "1px solid #ECECEC",
                paddingBottom: 5,
                marginBottom: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  className="title-purple"
                  style={{ margin: 0, marginRight: 20 }}
                >
                  Users
                </p>
                <p
                  className="font-bold"
                  style={{
                    margin: 0,
                    fontSize: 16,
                    color: "var(--gray-placeholder-light)",
                  }}
                >
                  <span style={{ color: "var(--gray-700)" }}>
                    {users.length}
                  </span>
                </p>
              </div>
              <div>
                <Button
                  className="primary-btn font-bold"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={onClickNewUser}
                >
                  <PlusOutlined style={{ marginRight: 8 }} />
                  <p className="font-bold" style={{ margin: 0 }}>
                    Add New User
                  </p>
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  style={{ marginRight: 20 }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={user.image}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="title-purple">{user.fullName}</span>
                  <span className="default-text">{user.emailAddress}</span>
                </div>
              </div>
              <Divider type="vertical" style={{ height: "3em" }} />
              <div>
                <p
                  className="font-medium"
                  style={{ margin: 0, color: "var(--gray-placeholder-light)" }}
                >
                  User Type
                </p>
                <p
                  className="font-medium"
                  style={{ margin: 0, color: "var(--gray-700)" }}
                >
                  {translate_user_type(user.user_type)}
                </p>
              </div>
              <Divider type="vertical" style={{ height: "3em" }} />
              <div>
                <p
                  className="font-medium"
                  style={{ margin: 0, color: "var(--gray-placeholder-light)" }}
                >
                  Calendar Connection
                </p>

                {user.calendar_id !== null && (
                  <p
                    className="font-medium"
                    style={{ margin: 0, color: "var(--gray-700)" }}
                  >
                    Connected
                  </p>
                )}
                {user.calendar_id === null && (
                  <p
                    className="font-medium"
                    style={{ margin: 0, color: "var(--gray-700)" }}
                  >
                    Disconected
                  </p>
                )}
              </div>
              <div>
                <Button
                  className="primary-btn-outline-round font-regular"
                  onClick={() =>
                    navigate("/profile", { state: { edit: true } })
                  }
                >
                  Edit Profile
                </Button>
              </div>
            </div>
            <div>
              <div
                className="font-medium"
                style={{
                  color: "var(--gray-700)",
                  fontSize: 16,
                  borderBottom: "1px solid #ECECEC",
                  marginBottom: 20,
                  marginTop: 25,
                  paddingBottom: 5,
                }}
              >
                Team Users
              </div>
              <div>
                {users != null &&
                  users.map((team_user, i) => {
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        className={`container-clickable-card ${styles["container-users"]}`}
                        onClick={() => navigate(`/team-user/${team_user.id}`)}
                        key={team_user.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: 200,
                            maxWidth: 200,
                          }}
                        >
                          {team_user.image.includes("amazonaws") && (
                            <Avatar
                              style={{ marginRight: 20 }}
                              size="default"
                              src={team_user.image}
                            />
                          )}
                          <div>
                            <p
                              className="font-bold"
                              style={{ color: "var(--gray-700)", margin: 0 }}
                            >
                              {team_user.fullName}
                            </p>
                            <p
                              className="font-medium"
                              style={{ color: "var(--gray-700)", margin: 0 }}
                            >
                              {team_user.emailAddress}
                            </p>
                          </div>
                        </div>
                        <Divider type="vertical" style={{ height: "3em" }} />
                        <div style={{ minWidth: 100, maxWidth: 100 }}>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-placeholder-light)",
                            }}
                          >
                            User Role
                          </p>
                          <p
                            className="font-medium"
                            style={{ margin: 0, color: "var(--gray-700)" }}
                          >
                            {translate_user_type(team_user.user_type)}
                          </p>
                        </div>
                        <Divider type="vertical" style={{ height: "3em" }} />
                        <div style={{ minWidth: 140, maxWidth: 140 }}>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-placeholder-light)",
                            }}
                          >
                            Calendar Connection
                          </p>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-700)",
                            }}
                          >
                            {team_user.calendar_id !== null && team_user.calendar_id !== ""
                              ? "Connected"
                              : "Disconnected"}
                          </p>
                        </div>
                        <Divider type="vertical" style={{ height: "3em" }} />
                        <div style={{ minWidth: 130, maxWidth: 130 }}>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-placeholder-light)",
                            }}
                          >
                            Last Sign In
                          </p>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-700)",
                            }}
                          >
                            {Moment(team_user.lastSeenAt).format("DD-MM-YYYY - H:m")}
                          </p>
                        </div>
                        <div>
                          <ChevronLeftPurple />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
}

export default CompanyProfile;
