import { Button, Switch, Tag, Tooltip } from "antd";
import { message } from "antd";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

// IMAGES
import { ReactComponent as Qualified } from "assets/img/lead/qualified_arrow.svg";
import { ReactComponent as Disqualified } from "assets/img/lead/disqualified_arrow.svg";
import { ReactComponent as Cam } from "assets/img/camera-video.svg";
import { ReactComponent as Trash } from "assets/img/trash-2.svg";
import { ReactComponent as Eye } from "assets/img/eye.svg";

// STYLES
import styles from "./form-card.module.css";
import { BASE_URL } from "../../baseURL";

function FormCard({ form, disabled, showFormUrl, onDeleteClick, onGetCodeClick, onGetLinkClick }) {
  const navigate = useNavigate()
  let qualified = 0;
  let meetings = 0;
  let unqualified = 0;
  let uncompleted = 0;
  let token = localStorage.getItem("token");
  const [video_call, setVideoCall] = useState(form.video_call);

  if (form.leads.length > 0) {
    for (var i in form.leads) {
      // Qualified leads
      if (form.leads[i].status === "qualified") qualified++;

      // Unqualified leads
      if (form.leads[i].status === "unqualified") unqualified++;

      // Uncompleted leads
      if (form.leads[i].status === "uncompleted") uncompleted++;

      // Meetings
      if (form.leads[i].meeting_date !== "0000-00-00 00:00:00") meetings++;
    }
  }

  const updateVideoCall = (event, id) => {
    updateForm(id, event);
  };

  const updateForm = async (id, checked) => {
    return await fetch(`${BASE_URL}/update_form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id: id,
        video_call: checked,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setVideoCall(checked);
        message.success("Form updated successfully");
        return json;
      })
      .catch((error) => {
        message.error("Error updating form");
        console.error(error);
      });
  };

  const showForm = () => {
    window.open(showFormUrl, '_blank');
  }

  const onEditForm = () => {
    navigate(`/edit-form/${form.id}`)
  }

  return (
    <div
      className={`container-lg ${styles.formCardItem}`}
      style={{ width: "30%", background: disabled ? "transparent" : "", padding: '24px' }}
    >
      <div className={styles.topActionButtons}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Cam style={{ marginRight: 15 }} />
          <Switch
            disabled={form.users_with_calendar_id.length === 0}
            defaultChecked
            checked={video_call}
            onChange={(event) => updateVideoCall(event, form.id)}
          />
        </div>
        <div>
          { !disabled && <Eye className={styles.eyeButton} onClick={showForm} /> }
          <Trash className={styles.trashButton} onClick={onDeleteClick} />
        </div>
      </div>
      <div>
        <div className="title-purple">{form.title}</div>
        <div className="font-regular" style={{ color: "var(--gray-700)" }}>
          { disabled ? <Tag> Draft </Tag> : form.domain }
        </div>
      </div>
      <div
        style={{
          display: "flex",
          padding: "10px 0 15px 0",
          justifyContent: "space-between",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="font-medium" style={{ fontSize: 28 }}>
            {form.completed_forms}
          </div>
          <div className="font-medium" style={{ fontSize: 12 }}>
            Completed
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="font-medium" style={{ fontSize: 28 }}>
            {uncompleted}
          </div>
          <div className="font-medium" style={{ fontSize: 12 }}>
            Uncompleted
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <div
            className="font-medium"
            style={{ fontSize: 28, color: "var(--primary)" }}
          >
            {meetings}
          </div>
          <div className="font-medium" style={{ fontSize: 12 }}>
            Meetings
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0",
          borderTop: "1px solid var(--gray-300)",
          borderBottom: "1px solid var(--gray-300)",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="font-medium"
        >
          <Qualified style={{ marginRight: 10 }} />
          <span style={{ fontSize: 18, marginRight: 5 }}>{qualified}</span>{" "}
          Qualified
        </div>
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="font-medium"
        >
          <Disqualified style={{ marginRight: 10 }} />
          <span style={{ fontSize: 18, marginRight: 5 }}>
            {unqualified}
          </span>{" "}
          Disqualified
        </div>
      </div>
      <div className={styles.isFlex}>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <Button
            className="primary-btn-outline-round font-bold"
            style={{ fontSize: 12 }}
            onClick={onEditForm}
          >
            Edit Form
          </Button>
        </div>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          { disabled ? (
            <Tooltip placement="top" title="Copy link is only available on active form">
              <Button
                className="primary-btn-rounded font-bold"
                style={{ fontSize: 12 }}
                disabled={disabled}
              >
                Get Link
              </Button>
            </Tooltip> ) : (
            <Button
              className="primary-btn-rounded font-bold"
              style={{ fontSize: 12 }}
              onClick={onGetLinkClick}
            >
              Get Link
            </Button>
          )}
        </div>
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          { disabled ? (
            <Tooltip placement="top" title="Copy code is only available on active form">
              <Button
                className="primary-btn-rounded font-bold"
                style={{ fontSize: 12 }}
                disabled={disabled}
              >
                Get Code
              </Button>
            </Tooltip> ) : (
            <Button
              className="primary-btn-rounded font-bold"
              style={{ fontSize: 12 }}
              onClick={onGetCodeClick}
            >
              Get Code
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormCard;
