import React from "react";
import { HolderOutlined } from "@ant-design/icons";
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useFormContext, Controller } from "react-hook-form";
import SwitchInput from "components/SwitchInput/SwitchInput";
import CheckboxInput from "components/CheckboxInput/CheckboxInput";

import "./components.module.css";
import styles from "./components.module.css";

const FixedQuestionsPanel = ({ fixedQuestions, setFixedQuestions }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  // --------------- SING INPUT ----------------
  const SingleInput = ({ question, id }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      setActivatorNodeRef,
    } = useSortable({ id });

    const sortableStyle = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const { control } = useFormContext();

    return (
      <>
        <div
          key={question.name}
          className={styles["container-single-input"]}
          ref={setNodeRef}
          style={sortableStyle}
        >
          <HolderOutlined
            className={styles["icon-holder"]}
            ref={setActivatorNodeRef}
            {...attributes}
            {...listeners}
          />
          <div
            className={`${styles.row} ${styles.between} ${styles["single-input"]}`}
          >
            <div className={`${styles.row}`}>
              <Controller
                name={`fixedQuestions[${question.key}].key`}
                control={control}
                defaultValue={question.key}
                render={({ field }) => (
                  <input type="hidden" {...field} />
                )}
              />
              <Controller
                name={`fixedQuestions[${question.key}].order`}
                control={control}
                defaultValue={question.order}
                render={({ field }) => (
                  <input type="hidden" {...field} />
                )}
              />
              <SwitchInput
                name={`fixedQuestions[${question.key}].active`}
                control={control}
                active={question.active}
                disabled={false}
              />
              <p className={styles["fixed-question"]}>{question.label}</p>
            </div>
            <div className={[styles.row]}>
              <p className={styles.required}>Required</p>
              <CheckboxInput
                name={`fixedQuestions[${question.key}].required`}
                required={question.required}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setFixedQuestions((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over.id);
        const updatedQuestions = arrayMove(prev, activeIndex, overIndex);
  
        const questionsWithOrder = updatedQuestions.map((question, index) => ({
          ...question,
          order: index + 1,
        }));
  
        return questionsWithOrder;
      });
    }
  };

  return (
    <>
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCenter}
      >
        <SortableContext
          items={fixedQuestions.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          {fixedQuestions.map((question, key) => {
            return (
              <SingleInput
                question={question}
                id={question.key}
                key={`${question.label}-${key}`}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </>
  );
};
export default FixedQuestionsPanel;
