import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useUser } from "../../context/UserContext";

import "./upgradePlan.module.css";
import styles from "./upgradePlan.module.css";

import { UpgradePlanButton } from "components/Buttons/Buttons";
import { CheckIcon, NoCheckIcon } from "components/CheckIcon/CheckIcon";

import { upgradePlan, getStripeUrl } from "services/api";

import circle from "../../assets/img/billingPlans/check-circle.svg";

const ProPlan = ({ user }) => {
  const { setPlan } = useUser();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const renderHeader = () => {
    return (
      <>
        <div
          className={`${styles["plan-header-container"]} ${styles["plan-pro-header"]}`}
        >
          <p className={styles["plan-pro-plan"]}>Profesional</p>
          <p className={styles["plan-pro-price"]}>59€ / month </p>
          <p className={styles["plan-pro-time"]}>up to 10 users</p>
        </div>
      </>
    );
  };
  const renderWorkspace = () => {
    return (
      <>
        <div className={styles["feat-pro-container"]}>
          <h3>Workspace:</h3>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Up to 5000 leads per form</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>From 2 to 10 Seats</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Unlimited dynamic form</p>
          </div>
        </div>
      </>
    );
  };
  const renderFeatures = () => {
    return (
      <>
        <div className={styles["feat-pro-container"]}>
          <h3>Features</h3>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Google Calendar connection</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Advanced scheduling</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Real-time qualification</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Route & assign leads</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Lead management</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Embed code</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Full-page form with Weyzo's subdomain</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Analytics</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Remove Weyzo's watermark</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Notification & email reminder</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Round robin assignment</p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Full-page form with custom domain
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Integrations with Salesforce, Hubspot and more
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Route & assign leads within your CRM
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Custom routing and assignment rules
            </p>
          </div>
          <div
            style={{ marginBottom: 0 }}
            className={styles["list-item-container"]}
          >
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Dedicated Account Management
            </p>
          </div>
        </div>
      </>
    );
  };

  const onClick = async () => {
    setLoading(true);
    try {
      const response = await getStripeUrl();
      if (response.error) {
        message.error("Error: Something went wrong");
        return;
      } else {
        window.location.replace(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      const token = query.get("token");
      upgradePlanPayed(token);
    }
    if (query.get("canceled")) {
      message.error("Oops, payment canceled");
      navigate("/");
    }
  }, []);

  const upgradePlanPayed = async (token) => {
    const response = await upgradePlan({ plan: "PROFESSIONAL", user, token });
    if (response.error) {
      message.success("Oops, upgrade plan failed. Contact Weyzo");
    } else {
      setPlan("PROFESSIONAL");
      message.success("Plan upgraded successfully");
    }
    navigate("/");
  };

  return (
    <>
      <div className={`${styles["plan-container"]} ${styles["plan-pro"]}`}>
        <div className={styles.popular}>
          <p>Most popular</p>
          <img src={circle} alt="Circle check" />
        </div>
        {renderHeader()}
        <UpgradePlanButton planLabel="PROFESSIONAL" onClick={onClick} loading={loading} />
        {renderWorkspace()}
        {renderFeatures()}
      </div>
    </>
  );
};
export default ProPlan;
