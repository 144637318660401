import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'
import ColorPicker from "components/ColorPicker/ColorPicker";
import UploadInput from "components/UploadInput/UploadInput";

import "./components.module.css";
import styles from "./components.module.css";
import switchStyles from "components/SwitchInput/switchInput.module.css";

const DesignPanel = ({
  setLogo,
  logo,
  primaryColor,
  secondaryColor,
  disabledWatermark,
  setDisabledWatermark,
  watermarkDisabled,
}) => {
  const { control } = useFormContext();

  const handleWatermarkToggle = (e) => {
    setDisabledWatermark(e.target.checked);
  };

  return (
    <>
      <UploadInput setLogo={setLogo} logo={logo} />
      <div id="design-panel" className={styles["container-color-picker"]}>
        <div id="primary-color-container">
          <p id="primary-color">Primary Color:</p>
          <ColorPicker
            name="design-primary-color"
            colorDefault={primaryColor ? primaryColor : "#8847e6"}
          />
        </div>
        <div id="background-color-container">
          <p id="background-color">Background Color:</p>
          <ColorPicker
            name="design-secondary-color"
            colorDefault={secondaryColor ? secondaryColor : "#FCF9F0"}
          />
        </div>
      </div>
      <div className={styles.watermarkSwitchContainer}>
        <span className={styles.watermarkSwitchText}>
          {" "}
          Remove Weyzo's Watermark:{" "}
        </span>
        <Controller
          name="watermark_disabled"
          control={control}
          defaultValue={disabledWatermark}
          render={({ field }) => (
            <label className={switchStyles.switch}>
              <input
                className={switchStyles.input}
                type="checkbox"
                {...field}
                disabled={watermarkDisabled}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleWatermarkToggle(e);
                }}
              />
              <span className={switchStyles.slider}></span>
            </label>
          )}
        />
        <div className={styles.watermarkDisabledInfo}>
          {
            watermarkDisabled && (
              <Tooltip placement="top" title="This feature is only available on the professional plan.">
                <InfoCircleOutlined />
              </Tooltip>
            )
          }
        </div>
      </div>
    </>
  );
};

export default DesignPanel;
