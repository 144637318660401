import React from "react";

import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
} from "@dnd-kit/core";

import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";

import { PlusCircleOutlined } from "@ant-design/icons";

import "./components.module.css";
import styles from "./components.module.css";

import CustomQuestion from "./CustomQuestion";

const CustomQuestionsPanel = ({ customFixedQuestions, setCustomFixedQuestions }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setCustomFixedQuestions((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over.id);
        const updatedQuestions = arrayMove(prev, activeIndex, overIndex);
  
        const questionsWithOrder = updatedQuestions.map((question, index) => ({
          ...question,
          order: index + 1,
        }));
  
        return questionsWithOrder;
      });
    }
  };

  const addNewCustomQuestion = () => {
    const lastCustom = customFixedQuestions[customFixedQuestions.length - 1];
    const newIndex = lastCustom ? Number(lastCustom.key) + 1 : 1;
    const newCustom = {
      key: `${newIndex}`,
      name: `custom-fixed-${newIndex}`,
      label: `Custom Fixed Question ${newIndex}`,
      placeholder: "Custom Question",
      value: "true",
      question_type: "custom_fixed",
      value_type: 'url',
      active: true,
      required: false
    };
    setCustomFixedQuestions((prev) => [...prev, newCustom]);
  };

  const onDelete = (e) => {
    if (e.target.parentElement.id) {
      setCustomFixedQuestions((prev) => {
        return prev.map((question) =>
          question.key === e.target.parentElement.id ? { ...question, active: false } : question
        );
      });
    }
  };

  return (
    <>
      <div className={styles.customQuestionsPanel}>
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={sensors}
          collisionDetection={closestCenter}
        >
          <SortableContext
            items={customFixedQuestions.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            {customFixedQuestions
              .filter(question => question.active)
              .map((question) => {
              return (
                <CustomQuestion
                  question={question}
                  id={question.key}
                  key={question.key}
                  setCustomFixedQuestions={setCustomFixedQuestions}
                  onDelete={onDelete}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <div
          className={styles.addNewCustomQuestionRow}
          onClick={addNewCustomQuestion}
        >
          <PlusCircleOutlined className={styles.addNewCustomQuestionIcon} />
          <p className={styles.addNewCustomQuestionText}> New Field </p>
        </div>
      </div>
    </>
  );
};

export default CustomQuestionsPanel;
