import React from "react";
import ColorPicker from "components/ColorPicker/ColorPicker";
import { useFormContext, Controller } from "react-hook-form";

import { Input } from "antd";

import "./components.module.css";
import styles from "./components.module.css";

const CtaButtonPanel = (props) => {
  const { control } = useFormContext();
  const { text, backgroundColor, textColor } = props;

  return (
    <div id="button-panel">
      <div className={styles["container-color-picker"]}>
        <div>
          <p className={styles["input-label-text"]}>Button Color:</p>
          <ColorPicker
            name="button-background-color"
            colorDefault={backgroundColor ? backgroundColor : "#8847e6"}
          />
        </div>
        <div>
          <p className={styles["input-label-text"]}>Text Color:</p>
          <ColorPicker
            name="button-text-color"
            colorDefault={textColor ? textColor : "#FFFFFF"}
          />
        </div>
      </div>
      <div>
        <div class="mt-32">
          <p className={styles["input-label-text"]}>
            CTA Text:
          </p>
        </div>
        <Controller
          name="button-text"
          control={control}
          defaultValue={text}
          render={({ field }) => (
            <Input size="large" value={text} placeholder="Add CTA Text" {...field} className={styles.Input} />
          )}
        />
      </div>
    </div>
  );
};

export default CtaButtonPanel;
