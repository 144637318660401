
export const translate_user_type = (user_type) => {
  switch (user_type) {
    case 'sales':
      return "member"
    break;
    case 'organization':
      return "admin"
    break;
    case 'superadmin':
        return "superadmin"
    break;
  }
}