import React from "react";
import { useWatch } from "react-hook-form";
import { Input, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import "../../formBuilder.module.css";
import styles from "../../formBuilder.module.css";
import "./preview.module.css";
import previewStyles from "./preview.module.css";

const FixedQuestionsComp = ({ control, question }) => {
  const renderQuestion = useWatch({
    control,
    name: `fixedQuestions[${question.key}].active`,
    defaultValue: question.active,
  });

  const requiredQuestion = useWatch({
    control,
    name: `fixedQuestions[${question.key}].required`,
    defaultValue: question.required,
  });

  const onClick = (e) => {
    console.log(e);
  };

  const items = [
    {
      label: <p>Spain</p>,
      key: "1",
    },
    {
      label: <p>France</p>,
      key: "2",
    },
  ];

  return (
    <div key={question.name} className={styles["preview-q-title"]}>
      {renderQuestion && (
        <>
          {question.name === "country" ? (
            <>
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                icon={DownOutlined}
                onClick={onClick}
                className={previewStyles["preview-dropdown"]}
              >
                <Button>
                  <p className="dropdown-text"> Country </p>
                  <DownOutlined />
                </Button>
              </Dropdown>
            </>
          ) : (
            <Input
              readOnly
              className={previewStyles["preview-input"]}
              placeholder={`${question.placeholder}${requiredQuestion ? "*" : ""}`}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FixedQuestionsComp;
