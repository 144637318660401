import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Union from "../../assets/img/Union.svg";
import Check from "../../assets/img/Vector.svg";
import { Button } from "antd";

function PasswordChanged() {
  let location = useLocation();
  let navigate = useNavigate();

  const backToWeyzo = () => {
    navigate("/login");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 40,
        }}
      >
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={Union}
          alt="Union"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: 80,
            height: 80,
            borderRadius: 100,
            backgroundColor: "#F3EDDE",
          }}
        >
          <img
            style={{ width: "auto", height: "auto" }}
            src={Check}
            alt="check"
          />
        </div>

        <h1 className={"font-bold"} style={{ fontSize: 30, marginTop: 30 }}>
          Password Changed!
        </h1>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <p className={"font-regular"} style={{ textAlign: "center" }}>
            Your password has been changed successfully.
          </p>
        </div>

        <div style={{ width: 450 }}>
          <Button
            size="large"
            style={{ height: 46 }}
            className="primary-btn font-regular full-btn"
            onClick={backToWeyzo}
          >
            Back to Weyzo's page
          </Button>
        </div>
      </div>
    </>
  );
}

export default PasswordChanged;
