import React from 'react';
import { useWatch } from "react-hook-form";
import { Progress } from "antd";
import styles from "./preview.module.css";
import QualifyingQuestionOptionsPreview from './QualifyingQuestionOptionsPreview';

const QualifyingQuestionsPreview = ({ control, question, length, index }) => {
  const renderQuestion = useWatch({
    control,
    name: `qualifyingQuestions[${question.key}].label`,
    defaultValue: question.label
  });

  const progressNumber = (id, length) => {
    const percent = ((id + 1) / length) * 100;
    return percent;
  };

  return (
    <>
      {
        (renderQuestion) && (
          <div className={styles["quali-container"]}>
            {index === 0 ? (
              <p className={styles["quali-percent-text"]}>
                Question {index + 1}/{length}
              </p>
            ) : (
              <p className={styles["quali-percent-text"]}>
                Question {index + 1}
              </p>
            )}
            {index === 0 && (
              <Progress
                showInfo={false}
                percent={progressNumber(
                  index,
                  length
                )}
                strokeColor="#A5d663"
              />
            )}
            <p className={styles["quali-title"]}>
              {renderQuestion}
            </p>
            <p className={styles["quali-option"]}>
              Select option
            </p>
            <div className={styles["container-answers"]}>
              {question.question_options &&
                question.question_options
                  .filter((option) => option.active)
                  .map((option, optionIndex) => {
                    return (
                      <QualifyingQuestionOptionsPreview
                        key={optionIndex}
                        option={option}
                        control={control}
                        questionKey={question.key}
                      />
                    )
                  })
              }
            </div>
          </div>  
        )
      }
    </>
  );
};

export default QualifyingQuestionsPreview;
