import React from "react";

import { UpgradeModalButton } from "components/Buttons/Buttons";

import "./modalUpgradePlan.module.css";
import styles from "./modalUpgradePlan.module.css";

import light from "../../../assets/img/billingPlans/lightning.svg";
import close from "../../../assets/img/buttonsLogos/plus.svg";

const ModalUpgradePlan = ({ children, open, onClose }) => {
  const onClickContainer = (e) => {
    if (e.target.id === "modal-container") {
      onClose();
    }
  };
  return (
    <>
      {open && (
        <div
          id="modal-container"
          className={styles["modal-container"]}
          onClick={onClickContainer}
        >
          <div id="modal-body" className={styles.modal}>
            <img
              onClick={onClose}
              className={styles["modal-close"]}
              src={close}
              alt="close icon"
            />
            <img
              className={styles["modal-light"]}
              src={light}
              alt="lightning icon"
            />
            <h2>Upgrade your plan</h2>
            <div>
              <p className={styles["modal-text"]}>{children}</p>
            </div>
            <UpgradeModalButton>Upgrade your plan</UpgradeModalButton>
          </div>
        </div>
      )}
    </>
  );
};
export default ModalUpgradePlan;
