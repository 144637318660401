import axios from "axios";
import { BASE_URL } from "baseURL";

const urls = {
  notification: `${BASE_URL}/emailNotifications`,
  report: `${BASE_URL}/emailReports`,
};

export const updateNotification = async ({ name, value, title }) => {
  const token = localStorage.getItem("token");

  let response;
  try {
    response = await axios.patch(
      urls[title],
      { [name]: value },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
    response = err.response;
  }
  console.log(response);
  return response;
};
