import "./App.css";
import Router from "routes/routes";
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <UserProvider>
      <Router />
    </ UserProvider >
  )
}

export default App;
