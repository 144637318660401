import React from "react";
import TextRich from "../../../components/TextRich/TextRich";

import { useFormContext, Controller } from "react-hook-form";

const LegalPanel = (props) => {
  const { control } = useFormContext();
  const { text } = props;

  return (
    <>
      <p>Legal Text:</p>
      <Controller
        name="legal-text"
        control={control}
        defaultValue={text}
        render={({ field }) => (
          <TextRich
            {...field}
            value={field.value}
            placeholder="By submitting this form, you are agreeing to company Privacy Policy. (Example text)"
          />
        )}
      />
    </>
  );
};

export default LegalPanel;
