const addQuestionOption = (questions, questionKey) => {
  return questions.map((question) => {
    if (question.key === questionKey) {
      const lastQualiOption = question.question_options[question.question_options.length - 1];
      const newIndex = lastQualiOption ? Number(lastQualiOption.key) + 1 : 1;

      return {
        ...question,
        question_options: [
          ...(question.question_options || []),
          {
            key: newIndex.toString(),
            label: "",
            value: "",
            order: newIndex + 1,
            qualify: false,
            active: true,
          },
        ],
      };
    }
    return question;
  });
};

const removeQuestionOption = (questions, questionKey, optionKey) => {
  return questions.map((question) => {
    if (question.key === questionKey) {
      const updatedOptions = question.question_options.map((option, index) => {
        if (option.key === optionKey) {
          return {
            ...option,
            active: false,
          };
        }
        return option;
      });

      return {
        ...question,
        question_options: updatedOptions,
      };
    }
    return question;
  });
};

const updateQuestionValue = (questions, questionKey, optionKey, newValue) => {
  return questions.map((question) => {
    if (question.key === questionKey) {
      const updatedOptions = question.question_options.map((option, index) => {
        if (option.key === optionKey) {
          return {
            ...option,
            value: newValue,
          };
        }
        return option;
      });

      return {
        ...question,
        question_options: updatedOptions,
      };
    }
    return question;
  });
};

const updateQuestionQualify = (questions, questionKey, optionKey, newQualify) => {
  return questions.map((question) => {
    if (question.key === questionKey) {
      const updatedOptions = question.question_options.map((option, index) => {
        if (option.key === optionKey) {
          return {
            ...option,
            qualify: newQualify,
          };
        }
        return option;
      });

      return {
        ...question,
        question_options: updatedOptions,
      };
    }
    return question;
  });
};

export {
  addQuestionOption,
  removeQuestionOption,
  updateQuestionValue,
  updateQuestionQualify,
};
