import React from "react";

import "./switchInput.module.css";
import styles from "./switchInput.module.css";

const SwitchInputGeneric = ({ onChange, value, name, title }) => {
  return (
    <>
      <label className={styles.switch}>
        <input
          className={styles.input}
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e, name)}
          title={title}
        />
        <span className={styles.slider}></span>
      </label>
    </>
  );
};
export default SwitchInputGeneric;
