import React from "react";
import { useFormContext } from "react-hook-form";

import "./checkboxInput.module.css";
import styles from "./checkboxInput.module.css";

const CheckboxInput = ({ name, required }) => {
  const { register } = useFormContext();

  return (
    <>
      <div className={styles.checkbox}>
        <input
          id={name}
          name={name}
          type="checkbox"
          {...register(name, { value: required })}
          defaultChecked={required}
        />
        <label htmlFor={name}>
          <span></span>
        </label>
      </div>
    </>
  );
};

export default CheckboxInput;
