import React from "react";
import { Button } from "antd";
import styles from './modal.module.css'

const Modal = ({ open, onClose, onDiscard, onSubmit, secondaryButtonText, primaryButtonText, children }) => {
  const onClickContainer = (e) => {
    if (e.target.id === "simple-modal-container") {
      onClose();
    }
  };
  return (
    <>
      {open && (
        <div
          id="simple-modal-container"
          className={styles['modal-container']}
          onClick={onClickContainer}
        >
          <div id="simple-modal-body" className={styles.modal}>
            <div>
              {children}
              <div className={styles['flex-buttons']}>
                <Button type="text" onClick={onDiscard ? onDiscard : onClose}> {secondaryButtonText} </Button>
                <Button type="text" className={styles['primary-button']} onClick={onSubmit}> {primaryButtonText} </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
