import { useEffect, useState } from "react";
import { Button, Switch, TimePicker } from "antd";
import { useNavigate } from "react-router-dom";
import WSelect from "components/w-select/w-select";
import moment from "moment";
// IMAGES
import { ReactComponent as GreenCheck } from "assets/img/availability/green-check.svg";
import { ReactComponent as Clock } from "assets/img/availability/clock.svg";
import { ReactComponent as ClockDisabled } from "assets/img/availability/clock_disabled.svg";
import { ReactComponent as Trash } from "assets/img/availability/trash-black.svg";
import { PlusOutlined, CalendarOutlined } from "@ant-design/icons";
import { useUser } from "../../context/UserContext";
import Loading from "components/Loading/Loading";
import timezonesData from 'utils/timezones.json';
import daysData from 'utils/days.json';

// STYLES
import { BASE_URL } from "../../baseURL";
import { message } from "antd";

const { RangePicker } = TimePicker;

function Availability() {
  const { user } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  const [availability, setAvailability] = useState([]);
  let token = localStorage.getItem("token");
  let navigate = useNavigate();

  useEffect(() => {
    setPageLoading(true);
    getAvailability(user);
    console.log("Granted Availability access to User");
  }, []);

  const getAvailability = (the_user) => {
    return fetch(`${BASE_URL}/availability`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        for (let i = 0; i <= 6; i++) {
          days[i].availableHours = [];
          days[i].available = false;
        }

        // Let's start the party
        // Here we set a complete object for non working hours with 24h/week
        var nwh = [];
        for (let i = 0; i <= 6; i++) {
          nwh.push({ weekday: i + 1, hours: [] });
          for (let x = 0; x <= 23; x++) {
            if (x < 10) {
              x = "0" + x;
            }
            nwh[i].hours.push(x + ":00");
            nwh[i].hours.push(x + ":30");
          }
        }

        json.forEach((element) => {
          var weekday = parseInt(element.weekday);

          // Here we set the values for our pickers
          days[weekday - 1].availableHours.push(element);
          days[weekday - 1].available = true;

          // Now, as the form only hides non_working hours, and what we have from the pickers is the oposite (working_hours),
          // we have tu invert. So, from the nwh array that we generated above, we'll remove the working hours from the pickers
          // So, we'll end up with an array of non_working_hours that we'll save in database for the form to hide only those.
          var start_range = parseInt(
            moment(element.start_time, "HH:mm").format("HH")
          );
          var end_range = parseInt(
            moment(element.end_time, "HH:mm").format("HH")
          );
          var my_index = 0;

          // For each loop of working hours, we'll remove them from non_working.
          // So, at this point I have start and end ranges. I gotta loop them and remove each value from nwh.
          for (let i = start_range; i <= end_range - 1; i++) {
            var time = start_range + my_index;
            if (time < 10) time = "0" + time;
            var time1 = time + ":00"; // Gotta remove two values, 00 and :30
            var time2 = time + ":30"; // Half an hour time, ex 12:30
            var index = nwh[weekday - 1].hours.indexOf(time1); // Search the index of the time1
            if (index !== -1) {
              // If found, let's remove it from the array.
              nwh[weekday - 1].hours.splice(index, 1);
            }

            // Just the same as above, but to remove half an hour times, ex: 12:30
            index = nwh[weekday - 1].hours.indexOf(time2);
            if (index !== -1) {
              nwh[weekday - 1].hours.splice(index, 1);
            }

            my_index++;
          }
        });

        updateUser(the_user.id, JSON.stringify(nwh));
        setAvailability(json);
        setPageLoading(false);
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateUser = (id, non_working_hours) => {
    return fetch(`${BASE_URL}/update_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id: id, non_working_hours: non_working_hours }),
    })
      .then((response) => response.json())
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [timezones, setTimezones] = useState(timezonesData);

  const [days, setDays] = useState(daysData);

  const addRange = (i, userId) => {
    var params = {
      start_time: "09:00:00",
      end_time: "18:00:00",
      user: userId,
      weekday: i,
    };

    return fetch(`${BASE_URL}/set_availability`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((json) => {
        getAvailability(user);
        message.success("Availability has been modified");
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteAvailability = (i) => {
    var params = {
      id: i,
    };

    return fetch(`${BASE_URL}/delete_availability`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((json) => {
        getAvailability(user);
        message.success("Availability has been modified");
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const selected = (e, id, weekday, userId) => {
    var start_time = moment(e[0]._d).format("HH:mm");
    var end_time = moment(e[1]._d).format("HH:mm");

    var params = {
      id: id,
      start_time: start_time,
      end_time: end_time,
      user: userId,
      weekday: weekday,
    };

    return fetch(`${BASE_URL}/update_availability`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((json) => {
        getAvailability(user);
        message.success("Availability has been modified");
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleDays = async (i, userId) => {
    let newDays = days;
    newDays[i].available = !newDays[i].available;

    if (!newDays[i].available) {
      days[i].availableHours.forEach((element) => {
        deleteAvailability(element.id);
      });

      await setDays([...newDays]);
    } else {
      addRange(i + 1, userId);
    }
  };

  const goToProfile = () => {
    navigate("/profile");
  }

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  return (
    availability !== null && (
      <>
        <div className="container-lg">
          <div style={{ marginBottom: 30 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottomColor: "#ECECEC",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                paddingBottom: 5,
              }}
            >
              <div className="title-purple" style={{ marginRight: 20 }}>
                Set your weekly hours
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <GreenCheck style={{ marginRight: 8 }} />
                <span
                  className="font-medium"
                  style={{ color: "var(--gray-700)", fontSize: 14 }}
                >
                  Default Schedule{" "}
                </span>
              </div>
              {user && !user.calendar_id && (
                <div>
                  <Button className="primary-btn font-medium" onClick={goToProfile}>
                    <CalendarOutlined />
                    Sync your Calendar
                  </Button>
                </div>
              )}
            </div>
            <div
              className="font-medium"
              style={{ color: "var(--gray-placeholder-dark)", marginTop: 8 }}
            >
              Choose a time below to edit or create a new one that you can apply
              to your event types
            </div>
            <div style={{ marginTop: 50, width: "50%" }}>
              <WSelect
                options={timezones}
                label="Time Zone"
                placeholder={"(GMT) Western Europe Time, London, Lisbon, Casablanca"}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              {days.map((day, index) => {
                return (
                  <div
                    className="container-card"
                    style={{
                      marginBottom: 10,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                        justifyContent: "flex-start",
                      }}
                    >
                      {day.available ? (
                        <Clock style={{ marginRight: 20 }} />
                      ) : (
                        <ClockDisabled style={{ marginRight: 20 }} />
                      )}
                      <span
                        className="font-medium"
                        style={{
                          color: day.available
                            ? "var(--primary)"
                            : "var(--gray-placeholder-light)",
                        }}
                      >
                        {day.name}
                      </span>
                    </div>
                    {day.available && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 3,
                        }}
                      >
                        {day.availableHours &&
                          day.availableHours.map((hours) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <RangePicker
                                  className="font-medium"
                                  minuteStep={30}
                                  onChange={(event) =>
                                    selected(
                                      event,
                                      hours.id,
                                      hours.weekday,
                                      user.id
                                    )
                                  }
                                  bordered={false}
                                  format={"HH:mm"}
                                  defaultValue={[
                                    moment(hours.start_time, "HH:mm"),
                                    moment(hours.end_time, "HH:mm"),
                                  ]}
                                  allowClear={false}
                                  suffixIcon={null}
                                />
                                <Button
                                  type="link"
                                  onClick={() => deleteAvailability(hours.id)}
                                  icon={<Trash />}
                                />
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {!day.available && (
                      <div
                        className="font-medium"
                        style={{
                          alignSelf: "center",
                          color: "var(--gray-700)",
                          flex: 3,
                          textAlign: "center",
                        }}
                      >
                        Not available
                      </div>
                    )}
                    <div
                      style={{
                        alignSelf: "center",
                        flex: 1,
                        textAlign: "right",
                      }}
                    >
                      {day.available && (
                        <Button
                          className="primary-btn-outline font-medium"
                          size="small"
                          onClick={() => addRange(day.id, user.id)}
                          style={{ marginRight: 30 }}
                        >
                          <PlusOutlined />
                          <span>Interval</span>
                        </Button>
                      )}

                      <Switch
                        checked={day.available}
                        onChange={() => toggleDays(index, user.id)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Availability;
