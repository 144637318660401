import React from 'react'

import './checkIcon.module.css'
import styles from './checkIcon.module.css'

import check from '../../assets/img/billingPlans/Check.svg'
import noCheck from '../../assets/img/billingPlans/Icons.svg'

export const CheckIcon = ({ pro }) => {
    return (
        <>
            <div className={`${styles['check-container']} ${styles['container']}`}>
                <img className={ styles.check} src={check} alt="Check Icon" />
            </div>
        </>
    )
}

export const NoCheckIcon = () => {
    return (
        <>
            <div className={`${styles['no-check-container']} ${styles['container']}`}>
                <img className={styles.check} src={noCheck} alt="No Check Icon" />
            </div>
        </>
    )
}