import React from "react";
import { Input } from "antd";
import { HolderOutlined, CloseOutlined } from "@ant-design/icons";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useFormContext, Controller } from "react-hook-form";
import SwitchInputQualy from "components/SwitchInput/SwitchInput_qualy";

import "./components.module.css";
import styles from "./components.module.css";

const QuestionOption = ({ id, option, name, onDelete, handleValueChange, handleQualifyChange }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id });

  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { control } = useFormContext();

  return (
    <>
      <div
        className={styles["container-single-input"]}
        ref={setNodeRef}
        style={sortableStyle}
      >
        <HolderOutlined
          className={styles["icon-holder"]}
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
        />
        <div
          className={`${styles.row} ${styles.between} ${styles["sortable-ans-container"]}`}
        >
          <Controller
            name={`${name}[${option.key}].active`}
            control={control}
            defaultValue={option.active}
            render={({ field }) => (
              <input type="hidden" {...field} />
            )}
          />
          <Controller
            name={`${name}[${option.key}].value`}
            control={control}
            defaultValue={option.value}
            render={({ field }) => (
              <Input
                placeholder="Field Title"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  handleValueChange(e);
                }}
              />
            )}
          />
          <p>Qualify</p>
          <SwitchInputQualy
            name={`${name}[${option.key}].qualify`}
            qualify={option.qualify}
            handleQualifyChange={handleQualifyChange}
          />
        </div>
        <CloseOutlined
          id={id}
          className={styles["icon-close"]}
          alt={id}
          onClick={onDelete}
        />
      </div>
    </>
  );
};

export default QuestionOption;
