import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

import "./preview.module.css";
import styles from "./preview.module.css";

const LegalComp = ({ control, value }) => {
  const [text, setText] = useState();
  const defaultLegalText =
    '<p>By submitting this form, you are agreeing to company <a href="#">Privacy Policy</a>.</p>';

  const legal = useWatch({
    control,
    name: "legal-text",
    defaultValue: value ? value : defaultLegalText,
  });

  const createMarkup = (text) => {
    return { __html: text };
  };

  useEffect(() => {
    setText(legal);
  }, [legal]);

  return (
    <>
      {text && (
        <div
          className={styles.legal}
          dangerouslySetInnerHTML={createMarkup(legal)}
        />
      )}
    </>
  );
};
export default LegalComp;
