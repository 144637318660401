import create from "zustand";

const useGlobalStore = create((set) => ({
  activeRoute: null,
  user: null,
  setActiveRoute: (menuElement) =>
    set((state) => ({ activeRoute: menuElement })),
  setUser: (userData) => {
    set((state) => ({ user: userData }));
  }
}));

export default useGlobalStore;
