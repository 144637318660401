import React, { useState, useEffect, createRef } from "react";

import { Skeleton } from "antd";

import "./uploadInput.module.css";
import styles from "./uploadInput.module.css";

const UploadInput = ({ setLogo, logo }) => {
  const [value, setValue] = useState();
  const [preview, setPreview] = useState();
  const fileRef = createRef();

  useEffect(() => {
    let objectUrl;
    if (value) {
      objectUrl = URL.createObjectURL(value);
      setPreview(objectUrl);
    }

    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  const onClick = () => {
    fileRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      updloadImage(file).then((base64Image) => {
        setLogo(base64Image);
        setValue(file);
      });
    }
  };

  const updloadImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  return (
    <>
      <div id="upload-container" className={styles["upload-container"]}>
        <div id="upload-preview" className={styles["upload-preview"]}>
          {value || logo ? (
            <img src={logo ? logo : preview} alt="logo-preview" />
          ) : (
            <Skeleton.Image />
          )}
        </div>
        <div id="upload-input-container">
          <input
            id="upload-input"
            name="form-logo"
            accept="image/*"
            type="file"
            className={styles.upload}
            ref={fileRef}
            onChange={handleFileChange}
          />
          <button
            type="button"
            id="fileSelect"
            className={styles["upload-button"]}
            onClick={onClick}
          >
            Upload Logo
          </button>
        </div>
      </div>
    </>
  );
};
export default UploadInput;
