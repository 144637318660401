import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import PrivateRoute from "helpers/PrivateRoute";
import FullWidthLayout from "views/Layouts/FullWidthLayout/FullWidthLayout";
import AppLayout from "views/Layouts/AppLayout/AppLayout";
import Dashboard from "views/Dashboard/Dashboard";
import Forms from "views/forms/forms";
import Leads from "views/leads/leads";
import Login from "views/login/login";
import CheckEmail from "../views/checkEmail/checkEmail";
import Register from "views/register/register";
import RegisterData from "../views/registerData/registerData";
import Lead from "views/lead/lead";
import Meetings from "views/meetings/meetings";
import Profile from "views/profile/profile";
import CompanyProfile from "views/company_profile/company_profile";
import TeamUser from "views/team-user/team-user";
import Availability from "views/availability/availability";
import NewUser from "views/NewEditUserCompany/newuser";

import PasswordReset from "views/password-reset/password-reset";
import PasswordChanged from "../views/passwordChanged/passwordChanged";

import NewForm from "views/FormBuilder/NewForm";
import EditForm from "views/FormBuilder/EditForm";
import UpgradePlan from "views/UpgradePlan/UpgradePlan";
import { useUser } from "../context/UserContext";

function Router() {
  const userContext = useUser();

  return (
    <Routes location={useLocation()}>
      <Route 
        path="/"
        element={
          <PrivateRoute>
            <AppLayout>
              <Dashboard />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/forms"
        element={
          <PrivateRoute>
            <AppLayout>
              <Forms />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/leads"
        element={
          <PrivateRoute>
            <AppLayout>
              <Leads />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/lead/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <Lead />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/meetings"
        element={
          <PrivateRoute>
            <AppLayout>
              <Meetings />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/profile"
        element={
          <PrivateRoute>
            <AppLayout>
              <Profile />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/company_profile"
        element={
          <PrivateRoute>
            <AppLayout>
              <CompanyProfile />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/team-user/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <TeamUser />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/availability"
        element={
          <PrivateRoute>
            <AppLayout>
              <Availability />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/user_company"
        element={
          <PrivateRoute>
            <AppLayout>
              <NewUser />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/user_company/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <NewUser />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/google-response"
        element={
          <PrivateRoute>
            <AppLayout>
              <Profile />
            </AppLayout>
          </PrivateRoute>
        } 
      />
      <Route
        path="/upgrade-plan"
        element={
          <PrivateRoute>
            <FullWidthLayout whiteBackground>
              <UpgradePlan />
            </FullWidthLayout>
          </PrivateRoute>
        }
      />
        <Route
          path="/edit-form/:id"
          element={
            <PrivateRoute>
              <FullWidthLayout>                
                <EditForm />
              </FullWidthLayout>
            </PrivateRoute>
          }
        />
        <Route 
          path="/form-builder"
          element={
            <PrivateRoute>
              <FullWidthLayout>                
                <NewForm />
              </FullWidthLayout>
            </PrivateRoute>
          } 
        />
      <Route
        path="/login"
        element={
          userContext.user ? (
            <Navigate to="/" replace />
          ) : (
            <FullWidthLayout>
              <Login />
            </FullWidthLayout>
          )
        }
      />
      <Route 
        path="/register"
        element={
          <FullWidthLayout>
            <Register />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/checkEmail"
        element={
          <FullWidthLayout>
            <CheckEmail />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/registerData/:token"
        element={
          <FullWidthLayout>
            <RegisterData />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/password-reset/:token"
        element={
          <FullWidthLayout>
            <PasswordReset />
          </FullWidthLayout>
        } 
      />
      <Route 
        path="/passwordChanged"
        element={
          <FullWidthLayout>
            <PasswordChanged />
          </FullWidthLayout>
        } 
      />
    </Routes>
  );
}

export default Router;
