import React from "react";

import logo from "../../assets/img/loading/Union.svg";
import spin from "../../assets/img/loading/Wrap.svg";

import "./loading.module.css";
import styles from "./loading.module.css";

const Loading = () => {
  return (
    <>
      <div className="weyzo-loader">
        <img src={spin} alt="spin weyzo" className={styles.spin} />
        <img src={logo} alt="logo weyzo" className={styles["logo-weyzo"]} />
      </div>
    </>
  );
};
export default Loading;
