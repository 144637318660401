import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import CheckboxInput from "components/CheckboxInput/CheckboxInput";

import { useSortable } from "@dnd-kit/sortable";
import { HolderOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";

import { CSS } from "@dnd-kit/utilities";

import "./components.module.css";
import styles from "./components.module.css";

const CustomQuestion = ({ question, id, setCustomFixedQuestions, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id });

  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { control } = useFormContext();

  const optionsSelect = [
    {
      value: "text",
      label: "Text",
      key: "1"
    },
    {
      value: "url",
      label: "URL",
      key: "2"
    },
    {
      value: "email",
      label: "E-mail",
      key: "3"
    },
    {
      value: "tel",
      label: "Phone",
      key: "4"
    },
  ];

  return (
    <>
      <div
        className={`${styles.row} ${styles.between} ${styles["custom-all"]}`}
        ref={setNodeRef}
        style={sortableStyle}
      >
        <HolderOutlined
          className={styles["icon-holder"]}
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
        />
        <div
          className={`${styles.row} ${styles["custom-question"]} ${styles.between}`}
        >
          <Controller
            name={`customFixedQuestions[${question.key}].key`}
            control={control}
            defaultValue={question.key}
            render={({ field }) => (
              <input type="hidden" {...field} />
            )}
          />
          <Controller
            name={`fixedQuestions[${question.key}].order`}
            control={control}
            defaultValue={question.order}
            render={({ field }) => (
              <input type="hidden" {...field} />
            )}
          />
          <Controller
            name={`customFixedQuestions[${question.key}].value_type`}
            control={control}
            defaultValue={question.value_type}
            render={({ field }) => (
              <Select
                className={styles["custom-select"]}
                options={optionsSelect}
                {...field}
                onChange={(selectedValue) => {
                  field.onChange(selectedValue);
                  setCustomFixedQuestions((prev) => {
                    const updatedQuestions = prev.map((q) => {
                      if (q.key === question.key) {
                        return {
                          ...q,
                          value_type: selectedValue,
                        };
                      }
                      return q;
                    });
                    return updatedQuestions;
                  });
                }}
              />
            )}
          />
          <Controller
            name={`customFixedQuestions[${question.key}].label`}
            control={control}
            defaultValue={question.id ? question.label : ''}
            render={({ field }) => (
              <Input {...field} placeholder="Custom Question" />
            )}
          />
          <div className={`${styles.row} ${styles["custom-required"]}`}>
            <p>Required</p>
            <CheckboxInput
              name={`customFixedQuestions[${question.key}].required`}
              required={question.required}
            />
          </div>
        </div>
        <CloseOutlined
          id={id}
          alt={question.label}
          className={styles["icon-close"]}
          onClick={onDelete}
        />
      </div>
    </>
  );
};

export default CustomQuestion;
