import React, { useState, useEffect } from "react";
import axios from 'axios'
import { message } from "antd";
import FormCard from "components/form-card/form-card";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../baseURL";
import { useUser } from '../../context/UserContext';

import EmptyLayout from "./components/EmptyLayout";
import Loading from "components/Loading/Loading";
import ModalUpgradePlan from "components/Modals/ModalUpgradePlan/ModalUpgradePlan";
import Modal from "components/Modals/Modal/Modal";

import "./forms.module.css";
import styles from "./forms.module.css";

import { PlusOutlined } from "@ant-design/icons";

const Forms = () => {
  let navigate = useNavigate();
  const { user, plan } = useUser();
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  let token = localStorage.getItem("token");
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalPrimaryButtonText, setModalPrimaryButtonText] = useState("")
  const [textToCopy, setTextToCopy] = useState('');
  const [isDeleteModal, setIsDeleteModal] = useState(true)
  const location = useLocation();

  useEffect(() => {
    setPageLoading(true);
    getData();
    console.log('User access granted to Forms')
  }, [location.state]);

  const getData = async () => {
    return await fetch(`${BASE_URL}/forms`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((forms) => {
        setData(forms);
        setPageLoading(false);
        return forms;
      })
      .catch((error) => {
        setPageLoading(false);
        console.error(error);
      });
  };

  const openNewFormBuilder = () => {
    if (plan === 'FREE' && data.length >= 1) {
      setShowUpgradePlanModal(true);
      return;
    }
    navigate("/form-builder");
  };

  const onCloseUpgradePlanModal = () => {
    setShowUpgradePlanModal(false);
  };

  const openDeleteModal = (form) => {
    setIsDeleteModal(true)
    setModalTitle('Are you sure you want to delete this form?')
    setModalPrimaryButtonText('Delete')
    setSelectedForm(form)
    setShowModal(true);
  }

  const openCopyCodeModal = (form) => {
    setIsDeleteModal(false)
    setSelectedForm(form)
    setTextToCopy(`<script id='weyzoFormJS' data-url='https://form.weyzo.io/${form.domain}/' src='https://app.weyzo.io/js/weyzoForm.js'></script>`)
    setModalTitle('Embed this form on your website (Javascript):')
    setModalPrimaryButtonText('Copy Code')
    setShowModal(true);
  }

  const openCopyLinkModal = (form) => {
    setIsDeleteModal(false)
    setSelectedForm(form)
    setTextToCopy(`https://form.weyzo.io/${form.domain}/`)
    setModalTitle('Link to this form on your website or email:')
    setModalPrimaryButtonText('Copy Link')
    setShowModal(true);
  }

  const onCloseModal = () => {
    setShowModal(false);
  };

  const archiveForm = async () => {
    const formData = {
      id: selectedForm.id,
      status: 'archived',
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    await axios.post(`${BASE_URL}/archive_form`, formData, { headers } )
      .then((res) => {
        message.success("Form deleted successfully");
        const updatedData = data.filter(form => form.id !== res.data.id);
        setData(updatedData);
        setShowModal(false);
      }).catch((err) => {
        message.error("Error deleting form");
        console.log(err)
      })
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        let textMessage = textToCopy.includes('script') ? "Code copied successfully" : "Link copied successfully"
        message.success(textMessage)
        setShowModal(false);
      })
      .catch(err => {
        message.error('Error deleting form: ', err);
      });
  };

  const renderForms = () => {
    return (
      <>
        <div className={styles["forms-container"]}>
          <div className={styles["forms-button-container"]}>
            <button className={styles["forms-button"]} onClick={openNewFormBuilder}>
              <PlusOutlined /> New Form
            </button>
          </div>
          <div className={styles.formCard}>
            {data.map((form, i) => {
              return (
                <FormCard
                  form={form}
                  disabled={form.status === "draft"}
                  showFormUrl={process.env.REACT_APP_FORM_HOST + "/" + form.domain + "/"}
                  onDeleteClick={() => openDeleteModal(form)}
                  onGetCodeClick={() => openCopyCodeModal(form)}
                  onGetLinkClick={() => openCopyLinkModal(form)}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  return (
    <>
      <ModalUpgradePlan open={showUpgradePlanModal} onClose={onCloseUpgradePlanModal}>
        You have a free Weyzo account which allows you to create only 1 form.
        Want to create more?
      </ModalUpgradePlan>

      <Modal
        open={showModal}
        onClose={onCloseModal}
        onSubmit={isDeleteModal ? archiveForm : copyToClipboard}
        secondaryButtonText="Cancel"
        primaryButtonText={modalPrimaryButtonText}
      >
        <p> { modalTitle } </p>
        { !isDeleteModal &&
          <textarea id="copyTextModal" className={styles.copyTextarea} name="copyTextarea">
            { textToCopy }
          </textarea> }
      </Modal>

      { data.length > 0
        ? renderForms()
        : <EmptyLayout openNewFormBuilder={openNewFormBuilder} />}
    </>
  );
}

export default Forms;
