import React, { useState, useRef, useEffect } from "react";
import { ChromePicker } from "react-color";
import { useFormContext, Controller } from "react-hook-form";

import "./colorPicker.module.css";
import styles from "./colorPicker.module.css";

const ColorPicker = ({ colorDefault, name }) => {
  const [color, setColor] = useState(colorDefault.toUpperCase());
  const [show, setShow] = useState(false);

  const { control } = useFormContext();

  let ref = useRef(null);

  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.addEventListener("click", setHide);

    return () => {
      document.removeEventListener("click", setHide);
    };
  }, [show]);

  const setHide = (e) => {
    if (e.target.id.includes("picker")) {
      return;
    }

    if (ref.current && !ref.current.contains(e.target)) {
      setShow(false);
    }
  
    if (show && e.target.id !== "" && !e.target.id.includes("rc"))  {
      setShow(false);
    }
  };

  const renderPicker = () => {
    return (
      <>
        <div id="color-container">
          <Controller
            name={name ? name : "color"}
            control={control}
            render={({ field }) => (
              <>
                <div ref={ref}> 
                  <ChromePicker
                    onChange={(color, event) => setColor(color.hex)}
                    onChangeComplete={(color, event) => setColor(color.hex)}
                    color={color}
                    {...field}
                  />
                </div>
              </>
            )}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={styles.containerPicker}
        onClick={onClick}
        onBlur={setHide}
        id="picker"
      >
        <div id="picker-div" className={styles.hex}>
          <p id="picker-text">{color}</p>
        </div>
        <div
          id="picker-color"
          className={styles.color}
          style={{ backgroundColor: color }}
        ></div>
      </div>
      {show && renderPicker()}
    </>
  );
};

export default ColorPicker;
