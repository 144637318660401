import React from 'react';

import styles from "./components.module.css";

const Watermark = ({ logo }) => {
  return (
    <div className={styles.watermark}> 
      <span> Powered by </span>           
      <img
        className={styles.watermarkLogo}
        src={logo}
        alt="weyzo-logo"
      />
    </div>
  )
}

export default Watermark;
